@font-face { font-family: "GothamPro"; src: url("../fonts/GothamProRegular.eot"); src: url("../fonts/GothamProRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProRegular.woff") format("woff"), url("../fonts/GothamProRegular.ttf") format("truetype"); font-style: normal; font-weight: 400; }

@font-face { font-family: "GothamPro"; src: url("../fonts/GothamProMedium.eot"); src: url("../fonts/GothamProMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProMedium.woff") format("woff"), url("../fonts/GothamProMedium.ttf") format("truetype"); font-style: normal; font-weight: 500; }

@font-face { font-family: "GothamPro"; src: url("../fonts/GothamProBold.eot"); src: url("../fonts/GothamProBold.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProBold.woff") format("woff"), url("../fonts/GothamProBold.ttf") format("truetype"); font-style: normal; font-weight: 700; }

@font-face { font-family: "GothamPro"; src: url("../fonts/GothamProBlack.eot"); src: url("../fonts/GothamProBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProBlack.woff") format("woff"), url("../fonts/GothamProBlack.ttf") format("truetype"); font-style: normal; font-weight: 900; }

html, body, div, span, h1, h2, h3, h4, h5, address, img, strong, i, p, center, ul, ol, li, form, label, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, footer, header, hgroup, menu, nav, section, input { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, footer, header, hgroup, menu, nav, section { display: block; }

body { position: relative; line-height: 1; }

a { text-decoration: none; color: black; outline: none; }

ul, ol { list-style: none; }

table { border-collapse: collapse; border-spacing: 0; }

body { width: 100%; height: 100%; font-size: 14px; font-family: 'GothamPro', sans-serif; background-color: white; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -moz-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; text-size-adjust: 100%; text-rendering: optimizeLegibility; }

.container { max-width: 100% !important; padding-left: 0 !important; padding-right: 0 !important; }

.clearfix:after { display: block; clear: both; content: ""; }

.content__descr { font-size: 14px; }

.content h1 { font-size: 36px; font-weight: 700; line-height: 42px; color: #402d8d; letter-spacing: 2px; text-transform: uppercase; }

.content h2 { font-size: 30px; font-weight: 500; line-height: 36px; margin-bottom: 35px; }

.content ol { list-style-type: decimal; list-style-position: inside; margin-bottom: 24px; }

.content ol li { font-size: 14px; color: #010101; text-align: left; line-height: 1.71; margin-bottom: 20px; }

.content ol li:last-child { margin-bottom: 0; }

.content small { font-size: 90%; }

.article__full-wrapper ul:not(.gallery-slider), .article__left ul:not(.gallery-slider), .article__text ul:not(.gallery-slider) { margin-bottom: 25px; }

.article__full-wrapper ul:not(.gallery-slider) li, .article__left ul:not(.gallery-slider) li, .article__text ul:not(.gallery-slider) li { position: relative; font-size: 16px; line-height: 18px; margin-bottom: 8px; padding-left: 15px; }

.article__full-wrapper ul:not(.gallery-slider) li:last-child, .article__left ul:not(.gallery-slider) li:last-child, .article__text ul:not(.gallery-slider) li:last-child { margin-bottom: 0; }

.article__full-wrapper ul:not(.gallery-slider) li:before, .article__left ul:not(.gallery-slider) li:before, .article__text ul:not(.gallery-slider) li:before { content: "\2022"; position: absolute; left: 0; top: 1px; display: block; }

.article__full-wrapper a, .article__left a, .article__text a { border-bottom: 1px solid #c1c1c1; transition: border-color 0.3s ease; }

.article__full-wrapper a:hover, .article__left a:hover, .article__text a:hover { border-bottom-color: #010101; }

.article__full-wrapper h3, .article__left h3, .article__text h3 { font-size: 18px; font-weight: 500; color: #000000; text-align: left; line-height: 18px; margin-bottom: 40px; }

p { font-size: 16px; color: #010101; line-height: 24px; margin-bottom: 23px; word-break: break-word; }

strong { font-weight: 500; }

i { font-style: italic; }

.btn { display: block; box-sizing: border-box; height: 50px; width: 180px; font-size: 12px; line-height: 45px; text-align: center; cursor: pointer; background-position: center; white-space: nowrap; }

.btn--padding { padding: 0 42px; }

.btn--auto { width: auto; display: inline-block; }

.btn--md { width: 150px; }

.btn--sm { width: 120px; }

.btn--yellow { background-color: #ffc907; color: #000; font-weight: 700; letter-spacing: 0.6px; line-height: 50px; transition: background-color 0.3s ease; }

.btn--yellow:hover { background-color: #ffd43a; }

.btn--blue { background-color: #402d8d; color: white; font-weight: 700; letter-spacing: 0.6px; line-height: 50px; border: none !important; transition: background-color 0.3s ease; }

.btn--blue:hover { background-color: #5239b4; }

.btn--white { border: 2px solid white; background-color: transparent; color: white; font-weight: 500; transition: color 0.3s ease, background-color 0.3s ease; }

.btn--white:hover, .btn--white.is-active { color: #402d8d; background-color: white; }

.btn--white.is-active { cursor: default; }

.btn--black { border: 2px solid black; background-color: transparent; color: black; font-weight: 500; transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease; }

.btn--black:hover, .btn--black.is-active { border-color: #402d8d; color: white; background-color: #402d8d; }

.btn--black.is-active { cursor: default; }

.btn--loaded { background-image: url("/static/img/bind-css/preloader.svg"); background-position: center; background-repeat: no-repeat; background-color: rgba(255, 255, 255, 0); border-color: rgba(255, 255, 255, 0); color: rgba(255, 255, 255, 0); transition: all 0.3s ease; pointer-events: none; }

.btn--loaded:hover { background-color: rgba(255, 255, 255, 0); border-color: rgba(255, 255, 255, 0); color: rgba(255, 255, 255, 0); cursor: default; }

.btn--full { width: 100%; }

.btn:active:not(.is-active) { opacity: 0.9; }

.btn.is-disable { opacity: 0.5; pointer-events: none; }

.big-btn { display: block; background-color: #ffc907; color: #fefefe; height: 80px; padding: 0 40px; font-size: 12px; line-height: 16px; font-weight: bold; text-align: center; cursor: pointer; border: none !important; transition: background-color 0.3s ease; }

.big-btn span { display: inline-block; vertical-align: middle; margin-top: 2px; }

.big-btn:before { content: ""; display: inline-block; vertical-align: middle; width: 0; height: 100%; }

.big-btn:hover { background-color: #ffd43a; }

.big-btn--blue { background-color: #402d8d; }

.big-btn--blue:hover { background-color: #5239b4; }

.big-btn--section { max-width: 180px; padding: 0 30px; }

.important-text { font-size: 36px; color: #010101; line-height: 43px; max-width: 680px; margin: 50px 100px 100px; }

.text-count { display: inline-flex; flex-direction: row; align-items: center; font-size: 12px; font-weight: 500; line-height: 1.5; cursor: pointer; border: none; background-color: transparent; padding: 0; font-family: GothamPro, sans-serif; outline: 0; }

.text-count:focus, .text-count:hover, .text-count:active { outline: 0; }

.text-count__label { color: #7a7a7a; }

.text-count__numb { margin-left: 5px; color: #5239b4; }

.container-title.text-center, .text-center { text-align: center; margin-left: auto; margin-right: auto; }

.content { position: relative; overflow: hidden; }

.container { width: 100%; padding-top: 15px; padding-bottom: 30px; }

.container--grey { background-color: #f2f2f2; }

.container-title { max-width: 580px; padding: 15px 0 20px; margin: 0; font-size: 30px; font-weight: 500; color: #010101; line-height: 36px; }

.container-title h1, .container-title h2, .container-title h3 { margin-bottom: 0; }

.container-title--center { text-align: center; margin: 0 auto; padding: 30px 0 30px; }

.container-title--bold { font-weight: bold; }

.container-top { padding: 30px 0 0; margin-bottom: 30px; }

.container-top--without-indent { margin-bottom: 0; }

.container-top--large-indent { margin-bottom: 30px; }

.wrapper { position: relative; width: 100%; max-width: 1220px; padding: 0 20px; box-sizing: border-box; margin: 0 auto; }

.header-wrapper { position: relative; }

.header { border-bottom: 1px solid #e8e8e8; padding: 10px 0 9px; }

.header .social-wrapper { display: none; }

.header.is-hidden { opacity: 0; visibility: hidden; height: 0; overflow: hidden; padding: 0; }

.header-wrapper { position: relative; z-index: 100; }

.header__inner { display: flex; justify-content: flex-start; align-items: center; }

.header__left { display: flex; justify-content: space-between; align-items: center; }

.header__logo { display: block; width: 180px; }

.header__logo-img { width: 100%; }

.header__logo-hide { position: absolute; height: 33px; width: 110px; left: 20px; top: -12px; visibility: hidden; margin-right: 0; opacity: 0; }

.header__contacts { display: flex; align-items: center; max-width: 500px; margin-left: 20px; margin-right: auto; }

.header.is-opened .nav { display: block; box-shadow: none; }

.header__btns { display: flex; flex-direction: row; align-items: center; }

.header__btn { margin-left: 15px; text-transform: uppercase; }

.header__btn:first-child { margin-left: 0; }

.header__flag { width: 30px; height: 30px; margin-left: 20px; }

.header__mobile-btns { display: none; flex-direction: row; align-items: center; }

.header__mobile-btns .contact-info { margin-bottom: 0; margin-right: 30px; }

.header__mobile-btns .search { margin-top: 0; }

.fixed-logo { margin-right: 23px; display: none; }

.fixed-logo img { display: block; max-width: 100%; }

.is-fixed .nav { padding: 5px 0; }

.is-fixed .nav > .wrapper { display: flex; align-items: center; }

.is-fixed .nav__inner { flex: 1 0 auto; }

.is-fixed .fixed-logo { display: block; }

.menu-btn { display: none; border: none; padding: 0; outline: none; cursor: pointer; margin: 0 13px 0 40px; width: 80px; height: 80px; background-color: #ffc90765; position: relative; }

.menu-btn span, .menu-btn span:after, .menu-btn span:before { position: absolute; left: 0; right: 0; margin: auto; width: 24px; height: 3px; background-color: #000; }

.menu-btn span { top: 12px; }

.menu-btn span:after { content: ''; top: 100%; margin-top: 4px; }

.menu-btn span:before { content: ''; top: 100%; margin-top: 11px; }

.menu-btn.is-opened:after { opacity: 1; }

.contact-info { display: block; font-size: 0; margin-right: 25px; }

.contact-info:last-child { margin-right: 0; }

.contact-info__icon { display: inline-block; vertical-align: middle; height: 30px; width: 30px; margin-right: 10px; }

.contact-info__icon-img { width: 100%; }

.contact-info__text { display: inline-block; vertical-align: middle; font-size: 12px; line-height: 1.5; }

.social-wrapper { font-size: 0; flex: 1 0 auto; margin-left: 25px; }

.social-btn { display: inline-block; vertical-align: middle; height: 30px; width: 30px; margin-right: 12px; transition: background 0.3s ease; }

.social-btn--fc { background: svg-load("../static/img/bind-css/social/facebook.svg"); }

.social-btn--fc:hover { background: svg-load("../static/img/bind-css/social/facebook.svg", fill=#402d8d); background-size: contain; }

.social-btn--yt { background: svg-load("../static/img/bind-css/social/youtube.svg"); }

.social-btn--yt:hover { background: svg-load("../static/img/bind-css/social/youtube.svg", fill=#402d8d); background-size: contain; }

.social-btn--in { background: svg-load("../static/img/bind-css/social/instagram.svg"); }

.social-btn--in:hover { background: svg-load("../static/img/bind-css/social/instagram.svg", fill=#402d8d); background-size: contain; }

.social-btn--vk { background: svg-load("../static/img/bind-css/social/vk.svg"); }

.social-btn--vk:hover { background: svg-load("../static/img/bind-css/social/vk.svg", fill=#402d8d); background-size: contain; }

.nav-wrapper { position: relative; }

.nav-wrapper.is-fixed { position: fixed; left: 0; top: 0; right: 0; background-color: #ffffff; box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15); }

.nav-wrapper.is-fixed .search-container { top: 100%; bottom: auto; border-top: 1px solid #e8e8e8; }

.nav { position: relative; }

.nav .social-wrapper { margin-top: 30px; }

.nav__close { opacity: 0; visibility: hidden; padding: 0; border: none; background-color: transparent; position: absolute; right: 5px; top: 0; width: 20px; height: 20px; }

.nav__inner { position: relative; display: flex; flex-direction: row; justify-content: space-between; align-items: center; }

.nav__list { font-size: 0; margin-left: -10px; }

.nav__item { display: inline-block; }

.nav__link { font-size: 12px; color: black; text-transform: uppercase; font-weight: 700; letter-spacing: 0.05em; transition: color 0.3s ease; padding: 24px 10px; display: block; background-color: transparent; }

.nav__link:hover { color: #402d8d; }

.nav__link--yellow { color: #ffc907; }

.nav__link--yellow:hover { color: #ffc907; }

.nav__link--main { color: #402d8d; }

.nav__link--main:hover { color: rgba(64, 45, 141, 0.8); }

.search { display: inline-block; vertical-align: bottom; margin-top: -5px; }

.search__inner { display: flex; flex-direction: row; align-items: center; }

.search__text { color: #402d8d; font-size: 12px; font-weight: 700; line-height: 1; letter-spacing: .6px; text-transform: uppercase; margin-left: 5px; }

.search-container { position: absolute; top: 0; left: 0; right: 0; background-color: #fff; display: none; }

.search-container.is-opened { display: block; box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15); }

.search-container__form { padding: 25px 0; display: flex; flex-direction: row; align-items: center; }

.search-container__label { flex: 1 0 auto; display: flex; flex-direction: row; align-items: center; cursor: pointer; }

.search-container__input { padding: 7px 0; border-bottom: 1px solid #402d8d; flex: 1 0 auto; outline: none; font-size: 12px; letter-spacing: .6px; text-transform: uppercase; font-weight: bold; color: #000000; }

.search-container__input ::-webkit-input-placeholder { opacity: .3; color: #402d8d; }

.search-container__input ::-moz-placeholder { opacity: .3; color: #402d8d; }

.search-container__input :-ms-input-placeholder { opacity: .3; color: #402d8d; }

.search-container__input :-moz-placeholder { opacity: .3; color: #402d8d; }

.search-container__icon { margin-right: 10px; }

.search-container__close { display: block; width: 19px; height: 19px; margin-left: 11px; cursor: pointer; }

.hero { position: relative; width: 100%; overflow: hidden; }

.hero > a { display: block; }

.hero__img { width: 100%; display: block; }

.hero__info { position: absolute; top: 53%; left: 50%; transform: translate(-50%, -50%); width: 100%; max-width: 620px; color: white; z-index: 1; text-align: center; }

.hero__title { font-size: 36px; line-height: 1.16; letter-spacing: 2.88px; font-weight: 700; text-transform: uppercase; margin-bottom: 20px; }

.hero__descr { margin-bottom: 59px; font-size: 14px; letter-spacing: 0.05em; line-height: 1.28; font-weight: 400; }

.hero__link .btn { margin: 0 auto; }

.footer { margin-top: auto; }

.footer__inner { padding: 50px 0 20px; }

.footer__top { display: flex; justify-content: space-between; }

.footer__categories { display: flex; justify-content: space-between; width: 74.5%; }

.footer__categories-list { max-width: 140px; }

.footer__categories li { margin-bottom: 13px; }

.footer__categories a { font-size: 12px; line-height: 18px; border-bottom: 1px solid rgba(255, 255, 255, 0); transition: border 0.3s ease; }

.footer__categories a:hover { border-color: rgba(0, 0, 0, 0.4); }

.footer__contacts .contact-info--location { margin-bottom: 25px; }

.footer__bottom { display: flex; justify-content: space-between; margin-top: 25px; }

.footer__bottom-left { display: flex; justify-content: space-between; align-items: center; width: 50.1%; }

.footer__studio-link { position: relative; display: flex; min-width: 115px; align-items: center; font-size: 10px; line-height: 12px; transition: opacity 0.3s ease; }

.footer__studio-link img { margin-right: 10px; width: 30px; height: 30px; }

.footer__studio-link:hover { opacity: 0.9; }

.footer__store-links { display: flex; align-items: center; }

.footer__appstore { display: block; height: 40px; width: 135px; transition: opacity 0.3s ease; margin-left: 20px; }

.footer__appstore-img { width: 100%; }

.footer__appstore:hover { opacity: 0.9; }

.footer__appstore:first-child { margin-left: 0; }

.footer__nav { margin-bottom: 15px; }

.footer__nav li { display: inline-block; vertical-align: middle; margin-right: 15px; }

.footer__nav li:last-child { margin-right: 0; }

.footer__nav a { font-size: 12px; font-weight: 700; letter-spacing: 0.05em; text-transform: uppercase; color: black; transition: color 0.3s ease; }

.footer__nav a:hover { color: #402d8d; }

.footer__rights { font-size: 12px; text-align: right; white-space: nowrap; }

.square-link { display: block; height: 80px; width: 80px; font-size: 12px; line-height: 16px; font-weight: 700; color: #fefefe; text-align: center; letter-spacing: 0.05em; margin-right: 20px; transition: background-color 0.3s ease; }

.square-link span { display: inline-block; vertical-align: middle; }

.square-link:before { content: ''; width: 0; height: 100%; display: inline-block; vertical-align: middle; }

.square-link--yellow { background-color: #ffc907; }

.square-link--yellow:hover { background-color: #ffd43a; }

.square-link--blue { background-color: #402d8d; }

.square-link--blue:hover { background-color: #5239b4; }

.pluses { padding-top: 15px; }

.pluses__inner { display: flex; flex-wrap: wrap; justify-content: center; align-items: flex-start; max-width: 980px; margin: 0 auto; }

.plus { width: 220px; text-align: center; margin-bottom: 44px; padding: 0 10px; }

.plus__icon { position: relative; height: 80px; width: 80px; border-radius: 50%; background-color: #402d8d; margin: 0 auto 14px; }

.plus__icon-img { position: absolute; max-width: 100%; max-height: 100%; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.plus__title { font-size: 16px; line-height: 24px; }

.section { padding-bottom: 20px; }

.section--without-padding { padding-bottom: 0; }

.section--with-margin { margin-bottom: 40px; }

.section__row { display: flex; flex-direction: row; margin-left: -20px; margin-right: -20px; }

.section__left, .section__right { width: 50%; padding-left: 20px; padding-right: 20px; }

.section__left { padding-right: 10px; }

.section__right { padding-left: 0; }

.section .container-title { display: flex; flex-direction: row; align-items: center; padding: 0 0 24px 0; margin: 0; }

.section .container-title__right { margin-left: 20px; }

.section .block-container, .section .block-container--advert { padding-top: 0; padding-bottom: 0; }

.files-list { margin: 40px 0 0; flex-direction: column; display: inline-flex; }

.files-list__item { margin-bottom: 20px; }

.files-list__item .file-link { width: 100%; }

.files-list__item:last-child { margin-bottom: 0; }

.file-link { display: inline-flex; flex-direction: row; align-items: center; font-size: 12px; font-weight: 500; line-height: 1; color: #000000; transition: color .3s; }

.file-link:hover { color: #5239b4; }

.file-link:hover .file-link__icon { fill: #5239b4; }

.file-link__icon { transition: fill .3s; fill: #000000; margin-right: 10px; }

.file-link__text { flex: 1 0 auto; }

.file-link__size { margin-left: 20px; color: #7a7a7a; }

.page { display: flex; flex-direction: column; min-height: 100vh; }

.page__content { flex: 1 0 auto; }

.text-icon-list { list-style: none; padding: 0; margin: 0; }

.text-icon-list__item { margin-bottom: 20px; }

.text-icon-list__item:last-child { margin-bottom: 0; }

.text-icon { text-decoration: none; color: #000000; display: inline-block; }

.text-icon__inner { display: inline-flex; align-items: center; flex-direction: row; }

.text-icon__icon { margin-right: 12px; }

.text-icon__text { font-size: 12px; font-weight: 500; line-height: 1.5; white-space: nowrap; }

.phone-list__item { display: inline-block; vertical-align: middle; margin-right: 3px; }

.phone-list__item:last-child { margin-right: 0; }

.phone { text-decoration: none; color: #000000; font-size: 18px; font-weight: bold; white-space: nowrap; border-bottom: none; line-height: 1.33; }

.actions-list .actions-list__item .slider { margin: 0; padding: 0; max-width: 580px; }

.hr-50 { height: 50px; width: 100%; background-color: #f2f2f2; }

.brand-list { margin-bottom: 50px; }

.flex-end { display: flex; justify-content: flex-end; }

.scroll-to-top { position: fixed; right: 25px; bottom: 25px; border: none; background-color: transparent; -webkit-border-radius: 50%; -moz-border-radius: 50%; border-radius: 50%; overflow: hidden; cursor: pointer; outline: 0; z-index: 9999; padding: 0; }

.events-list { padding-top: 10px; }

.slider { margin-right: -15px; margin-left: -15px; padding: 0 15px; }

.slider--indent { padding-top: 15px; }

.slider--indent .swiper-wrapper { height: auto !important; align-items: initial !important; }

.slider--single .swiper-wrapper > * { width: auto; max-width: 100%; }

.slider .slide:last-child { margin-right: 0; }

.slider .swiper-pagination--inside { position: absolute; bottom: 20px; margin: 0; }

.slider-subcontainer { max-width: 580px; margin-left: auto; }

.js-banner-slider.slider--indent { padding-bottom: 12px; }

.swiper-btn { position: absolute; top: 0; bottom: 0; width: 80px; padding: 0; margin: 0; background-color: transparent; border: none; font-size: 0; z-index: 1; outline: none; cursor: pointer; }

.swiper-btn span { display: block; margin: auto; width: 16px; height: 16px; border: 4px solid #fff; border-right: none; border-bottom: none; }

.swiper-btn-prev { left: 0; background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)); }

.swiper-btn-prev span { transform: rotate(-45deg); }

.swiper-btn-next { right: 0; background-image: linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)); }

.swiper-btn-next span { transform: rotate(135deg); }

.swiper-wrapper { width: calc(100% + 20px); height: auto; }

.slide { position: relative; flex-shrink: 0; box-sizing: border-box; margin-right: 20px; overflow: hidden; transition: box-shadow 0.3s ease; will-change: box-shadow; }

.slide:hover { box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); }

.slide-status { max-width: 180px; text-align: center; font-size: 12px; font-weight: 500; padding: 19px 0; }

.slide__hero { height: auto; }

.sl-event { width: calc(50% - 20px); border: 1px solid #aaa; display: flex; flex-flow: column nowrap; }

.sl-event_full { width: 100%; }

.sl-event_mb { margin-bottom: 40px; }

.sl-event__preview { display: block; width: 100%; }

.sl-event__inner { width: 100%; box-sizing: border-box; padding: 30px 30px 25px; flex: 1 0 auto; }

.sl-event__info { display: flex; flex-direction: column; justify-content: space-between; height: 100%; }

.sl-event__info .sl-event__date, .sl-event__info .sl-event__title { text-shadow: 0 0 5px #fff; }

.sl-event__info-bottom { position: relative; margin-top: auto; }

.sl-event__info-bottom:after { max-width: 180px; content: ''; position: absolute; z-index: -1; top: 0; bottom: 0; left: 0; right: 0; background-color: rgba(255, 255, 255, 0.7); }

.sl-event__date { font-size: 12px; font-weight: 500; margin-bottom: 23px; }

.sl-event__title { font-size: 18px; font-weight: 700; letter-spacing: 0.9px; line-height: 1.33; text-transform: uppercase; margin-bottom: 30px; }

.sl-event__descr { font-size: 14px; font-weight: 500; line-height: 18px; max-height: 125px; overflow: hidden; }

.sl-news { width: calc(50% - 20px); border: 1px solid #aaa; }

.sl-news__inner { display: flex; flex-flow: column nowrap; height: 100%; }

.sl-news__preview { position: relative; height: 100%; overflow: hidden; }

.sl-news__preview-img { width: 100%; display: block; height: 100%; object-fit: cover; }

.sl-news__info { display: flex; flex-direction: column; justify-content: space-between; flex-shrink: 0; box-sizing: border-box; padding: 30px 30px 25px; background-color: #ffc907; text-align: right; color: white; }

.sl-news__info-bottom .btn { margin-left: 30px; }

.sl-news__info-bottom { margin-left: auto; }

.sl-news__date { font-size: 12px; font-weight: 500; margin-bottom: 23px; }

.sl-news__title { font-size: 18px; font-weight: bold; letter-spacing: 0.05em; line-height: 24px; margin-bottom: 30px; text-transform: uppercase; }

.sl-news__descr { font-size: 14px; font-weight: 500; line-height: 18px; max-height: 125px; overflow: hidden; }

.sl-advert { width: calc(50% - 20px); }

.sl-advert__inner { display: flex; flex-flow: column nowrap; height: 100%; }

.sl-advert__preview { position: relative; overflow: hidden; }

.sl-advert__preview-img { width: 100%; display: block; max-height: 400px; min-height: 325px; height: 100%; object-fit: cover; }

.sl-advert__info { display: flex; flex-direction: column; justify-content: space-between; flex-shrink: 0; box-sizing: border-box; background-color: #402d8d; text-align: left; color: white; padding: 30px 30px 25px; }

.sl-advert__icon { float: right; width: 40px; height: 40px; margin-right: 30px; margin-bottom: 23px; background-size: contain; background-repeat: no-repeat; background-position: center; text-align: right; }

.sl-advert__icon-img { max-width: 100%; }

.sl-advert__info-bottom { margin-top: 20px; }

.sl-advert__descr { font-size: 16px; line-height: 24px; max-height: 120px; overflow: hidden; }

.sl-advert__descr:before { content: ""; display: block; clear: both; }

.sl-advert-full { width: calc(50% - 20px); display: flex; align-items: center; }

.sl-advert-full__inner { display: flex; width: 100%; box-sizing: border-box; padding: 83px 40px 35px 50px; }

.sl-advert-full__preview { position: relative; width: calc(100% - 280px); overflow: hidden; }

.sl-advert-full__preview-img { position: absolute; position: absolute; top: 0; right: 0; width: 100%; height: 100%; object-fit: cover; }

.sl-advert-full__info { display: flex; flex-direction: column; justify-content: space-between; flex-shrink: 0; width: 100%; max-width: 420px; text-align: left; color: white; }

.sl-advert-full__info p { margin-bottom: 0; }

.sl-advert-full__info-top { max-height: 220px; overflow: hidden; }

.sl-advert-full__title { font-size: 12px; font-weight: 700; margin-bottom: 26px; }

.sl-advert-full__title-big { display: block; margin-top: 5px; font-size: 48px; font-weight: 900; text-transform: uppercase; }

.sl-advert-full__logo { position: absolute; width: 150px; height: 75px; top: 40px; right: 40px; text-align: right; }

.sl-advert-full__logo img { width: auto; max-width: 100%; max-height: 100%; }

.sl-advert-full__descr { font-size: 14px; font-weight: 500; line-height: 18px; }

.sl-advert-full--yellow { background-color: #ffc907; }

.sl-advert-full--yellow .sl-advert-full__title { color: black; }

.sl-advert-full--yellow .sl-advert-full__descr, .sl-advert-full--yellow .sl-advert-full__descr p, .sl-advert-full--yellow .sl-advert-full__descr li { color: black; }

.sl-advert-full--yellow .btn { border: 2px solid black; background-color: transparent; color: black; font-weight: 500; transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease; }

.sl-advert-full--yellow .btn:hover, .sl-advert-full--yellow .btn.is-active { border-color: #402d8d; color: white; background-color: #402d8d; }

.sl-advert-full--yellow .btn.is-active { cursor: default; }

.sl-advert-full--blue { background-color: #402d8d; }

.sl-advert-full--blue .btn { border: 2px solid white; background-color: transparent; color: white; font-weight: 500; transition: color 0.3s ease, background-color 0.3s ease; }

.sl-advert-full--blue .btn:hover, .sl-advert-full--blue .btn.is-active { color: #402d8d; background-color: white; }

.sl-advert-full--blue .btn.is-active { cursor: default; }

.sl-advert-full--blue .sl-advert-full__descr, .sl-advert-full--blue .sl-advert-full__descr p, .sl-advert-full--blue .sl-advert-full__descr li { color: white; }

.sl-advert-full--white { background-color: white; }

.sl-advert-full--white .sl-advert-full__info { color: black; }

.sl-advert-full--white .sl-advert-full__title { color: #e31e25; }

.sl-advert-full--white .btn { border: 2px solid black; background-color: transparent; color: black; font-weight: 500; transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease; }

.sl-advert-full--white .btn:hover, .sl-advert-full--white .btn.is-active { border-color: #402d8d; color: white; background-color: #402d8d; }

.sl-advert-full--white .btn.is-active { cursor: default; }

.sl-advert-full--bf .sl-advert-full__title { margin-bottom: 7px; }

.sl-advert-full--bf .sl-advert-full__info { max-width: 100%; }

.sl-advert-full--bf .sl-advert-full__descr { font-size: 16px; font-weight: 400; line-height: 24px; }

.sl-advert-full--bf .sl-advert-full__descr ul li { font-size: 16px; line-height: 24px; margin-bottom: 0; }

.sl-advert-small { width: calc(25% - 20px); min-width: 260px; }

.sl-advert-small__inner { display: flex; height: 100%; box-sizing: border-box; padding: 110px 40px 40px; }

.sl-advert-small__info { display: flex; flex-direction: column; justify-content: space-between; flex-shrink: 0; width: 100%; max-width: 420px; text-align: left; color: white; }

.sl-advert-small__info p { margin-bottom: 0; }

.sl-advert-small__info-top { max-height: 220px; overflow: hidden; flex: 1 0 auto; display: flex; }

.sl-advert-small__info-bottom .btn { margin: 0 auto; }

.sl-advert-small__title { font-size: 12px; font-weight: 700; margin-bottom: 26px; }

.sl-advert-small__title-big { display: block; margin-top: 5px; font-size: 48px; font-weight: 900; text-transform: uppercase; }

.sl-advert-small__logo { position: absolute; width: 80px; height: 40px; top: 40px; right: 40px; text-align: right; }

.sl-advert-small__logo img { width: auto; max-width: 100%; max-height: 100%; }

.sl-advert-small__scheme { position: absolute; top: 50px; left: 40px; width: 65px; font-size: 24px; font-weight: 900; color: white; }

.sl-advert-small__scheme a { color: white; transition: opacity 0.3s ease; }

.sl-advert-small__scheme a:hover { opacity: 0.9; }

.sl-advert-small__descr { font-size: 16px; line-height: 24px; }

.sl-advert-small__descr-big { align-self: center; font-size: 24px; font-weight: 900; line-height: 36px; text-transform: uppercase; }

.sl-advert-small--yellow { background-color: #ffc907; }

.sl-advert-small--yellow .btn { border: 2px solid black; background-color: transparent; color: black; font-weight: 500; transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease; }

.sl-advert-small--yellow .sl-advert-small__descr, .sl-advert-small--yellow .sl-advert-small__descr p, .sl-advert-small--yellow .sl-advert-small__descr li { color: black; }

.sl-advert-small--yellow .btn:hover, .sl-advert-small--yellow .btn.is-active { border-color: #402d8d; color: white; background-color: #402d8d; }

.sl-advert-small--yellow .btn.is-active { cursor: default; }

.sl-advert-small--blue { background-color: #402d8d; }

.sl-advert-small--blue .sl-advert-small__descr, .sl-advert-small--blue .sl-advert-small__descr p, .sl-advert-small--blue .sl-advert-small__descr li { color: white; }

.sl-advert-small--blue .btn { border: 2px solid white; background-color: transparent; color: white; font-weight: 500; transition: color 0.3s ease, background-color 0.3s ease; }

.sl-advert-small--blue .btn:hover, .sl-advert-small--blue .btn.is-active { color: #402d8d; background-color: white; }

.sl-advert-small--blue .btn.is-active { cursor: default; }

.sl-advert-small--white { background-color: white; }

.sl-advert-small--white .sl-advert-small__info, .sl-advert-small--white .sl-advert-small__scheme, .sl-advert-small--white .sl-advert-small__scheme a { color: black; }

.sl-advert-small--white .sl-advert-small__descr, .sl-advert-small--white .sl-advert-small__descr p, .sl-advert-small--white .sl-advert-small__descr li { color: black; }

.sl-advert-small--white .sl-advert-small__descr-big { color: #e31e25; }

.sl-advert-small--white .btn { border: 2px solid black; background-color: transparent; color: black; font-weight: 500; transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease; }

.sl-advert-small--white .btn:hover, .sl-advert-small--white .btn.is-active { border-color: #402d8d; color: white; background-color: #402d8d; }

.sl-advert-small--white .btn.is-active { cursor: default; }

.products-list { padding-top: 25px; }

.sl-product { width: calc(25% - 20px); min-width: 260px; background-color: white; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15); display: flex; flex-direction: column; }

.sl-product__link { position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1; }

.sl-product__preview { position: relative; width: 100%; height: 200px; }

.sl-product__preview-img { position: absolute; top: 0; right: 0; width: 100%; height: 100%; object-fit: cover; }

.sl-product__bage { position: absolute; bottom: 20px; right: 20px; height: 50px; width: 50px; border-radius: 50%; background-color: #402d8d; text-transform: uppercase; text-align: center; font-size: 12px; font-weight: 700; color: white; line-height: 50px; }

.sl-product__info { display: flex; flex-direction: column; flex: 1 0 auto; box-sizing: border-box; padding: 13px 35px 30px; text-align: center; }

.sl-product__category { font-size: 12px; font-weight: bold; color: #bdbdbd; text-transform: uppercase; letter-spacing: 0.6px; line-height: 1.33; margin-bottom: 10px; position: relative; z-index: 1; }

.sl-product__title { height: 35px; overflow: hidden; line-height: 1.5; margin-bottom: 10px; font-size: 12px; }

.sl-product__price-new { position: relative; display: inline-block; font-size: 30px; font-weight: 900; color: #402d8d; }

.sl-product__price-new:before { content: '00'; position: absolute; top: 2px; left: 110%; font-size: 14px; letter-spacing: 0.05em; }

.sl-product__price-old { position: relative; display: inline-block; margin-top: 12px; font-size: 18px; font-weight: 900; }

.sl-product__price-old:before { content: '00'; position: absolute; top: 2px; left: 110%; font-size: 8px; letter-spacing: 0.05em; }

.sl-product__price-old:after { content: ''; position: absolute; width: 170%; height: 2px; background-color: #E11D2A; top: 50%; left: 50%; transform: translate(-50%, -50%) rotate(10deg); }

.sl-product__shop { display: inline-block; vertical-align: bottom; font-size: 12px; font-weight: 700; line-height: 1.5; color: #000000; text-decoration: none; text-transform: lowercase; position: relative; z-index: 1; }

.sl-product__shop:first-letter { text-transform: uppercase; }

.sl-product__shop-wrapper { flex: 1 0 auto; display: flex; flex-direction: column; align-items: center; justify-content: flex-end; margin-top: 30px; }

.sl-product__shop-code { display: block; color: #402d8d; padding-top: 5px; }

.slider-pagination-container { padding-top: 25px; position: relative; display: flex; align-items: center; justify-content: center; font-size: 0; height: 55px; }

.slider-pagination-container_without-padding { padding-top: 0; }

.slider-btn { height: 20px; width: 12px; padding: 0 2px; background-image: url(/static/img/bind-css/slider-arrow.svg); background-size: 200%; background-position: center center; cursor: pointer; }

.slider-btn-next { transform: rotate(180deg); transform-origin: center center; }

.slider-link { position: absolute; right: 0; bottom: 2px; font-size: 12px; font-weight: 500; }

.slider-link a { color: black; line-height: 14px; border-bottom: 1px solid rgba(255, 255, 255, 0); transition: border 0.3s ease; }

.slider-link a:hover { border-color: rgba(0, 0, 0, 0.4); }

.swiper-pagination { position: relative; margin: 0 20px; bottom: 0; }

.swiper-pagination-bullet { height: 10px; width: 10px; box-sizing: border-box; background-color: transparent; border: 2px solid #7a7a7a; border-radius: 10px; opacity: 1; margin-right: 10px; transition: border-color 0.3s ease; }

.swiper-pagination-bullet:last-child { margin-right: 0; }

.swiper-pagination-bullet:hover { border-color: #5239b4; }

.swiper-pagination-bullet-active { border-color: #ffc907; }

.swiper-pagination-bullet-active:hover { border-color: #ffc907; }

.swiper-button-disabled { opacity: 0.5; cursor: default; }

.brand-slider { margin-right: -15px; margin-left: -15px; padding: 15px 15px 0; }

.brand-slider .swiper-wrapper { align-items: center; }

.brand-slide { position: relative; flex-shrink: 0; width: calc(100% / 6 - 20px); margin-right: 20px; overflow: hidden; opacity: 0.7; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15); transition: box-shadow 0.3s ease, opacity 0.3s ease; will-change: box-shadow, opacity; display: flex; align-items: center; }

.brand-slide:last-child { margin-right: 0; }

.brand-slide:hover { opacity: 1; box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); }

.brand-slide > a { display: block; }

.brand-slide__logo { display: block; max-width: 100%; }

.review { width: calc(50% - 20px); background-color: white; height: auto !important; min-height: 300px; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15); }

.review__inner { padding: 40px 50px; box-sizing: border-box; }

.review__top { display: flex; align-items: center; margin-bottom: 18px; }

.review__thumb { position: relative; flex-shrink: 0; height: 80px; width: 80px; background-color: #e8e6cd; border-radius: 50%; margin-right: 18px; }

.review__thumb-img { position: absolute; top: 50%; left: 50%; width: 100%; height: 100%; transform: translate(-50%, -50%); }

.review__author { font-size: 16px; line-height: 24px; }

.review__text { font-size: 12px; color: #010101; line-height: 18px; }

.gallery { opacity: 0; transition: opacity 0.3s ease; }

.gallery.gallery_without-p .lSSlideOuter .lSPager.lSGallery { padding-bottom: 0; }

.gallery.is-visible { opacity: 1; }

.gallery_sm { max-width: 580px; }

.gallery-img-wrapper { max-height: 768px; }

.gallery-img-wrapper img { display: block; width: 100%; }

.gallery--hide-btn .lSPrev, .gallery--hide-btn .lSNext { display: none; }

.lSSlideOuter { margin: 0 -10px 0 !important; padding: 0 10px 0; }

.lSSlideOuter .lSPager.lSGallery { margin: 20px -10px 0 !important; padding: 0 10px 20px; }

.lSSlideOuter .lSPager.lSGallery li { max-width: 80px !important; opacity: 0.7; transition-property: opacity, box-shadow; transition-duration: 0.3s; transition-timing-function: ease; }

.lSSlideOuter .lSPager.lSGallery li:last-child { margin-right: 0 !important; }

.lSSlideOuter .lSPager.lSGallery li.active, .lSSlideOuter .lSPager.lSGallery li:hover { border-radius: 0 !important; box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2) !important; }

.lSSlideOuter .lSPager.lSGallery li.active { opacity: 1; }

.lSSlideOuter .lSPager.lSGallery li:hover:not(.active) { opacity: 0.85; }

.lSAction > a { width: 80px; height: 80px; background-color: #f5f5f5; background: #f5f5f5 url("/static/img/bind-css/slider-arrow-black.png") no-repeat center; opacity: 1; }

.lSAction > .lSPrev { background-position: center; transform: rotate(180deg); }

.lSAction > .lSNext { background-position: center; }

.interior-block { padding-bottom: 30px; }

.interior { width: 100%; display: flex; height: 400px; background-color: white; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15); }

.interior__preview { width: calc(100% - 380px); position: relative; overflow: hidden; }

.interior__preview-img { position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; }

.interior__info { position: relative; display: flex; flex-direction: column; justify-content: space-between; width: 380px; height: 100%; box-sizing: border-box; padding: 46px 40px 40px 50px; }

.interior__text { font-size: 48px; font-weight: 900; margin-bottom: 30px; }

.interior__title { font-size: 16px; font-weight: 500; line-height: 24px; margin-bottom: 33px; }

.interior__descr { font-size: 14px; line-height: 18px; }

.interior__icon { position: absolute; top: 40px; right: 40px; width: 40px; height: 40px; }

.interior__icon-img { width: 100%; }

.interior--special { margin-bottom: 7px; }

.interior--special .interior__preview { width: calc(100% - 280px); }

.interior--special .interior__info { width: 280px; }

.interior--special .interior__info { padding: 100px 50px 40px; }

.intr-scheme { position: relative; }

.intr-scheme__preview { position: relative; overflow: hidden; }

.intr-scheme__preview:after { content: ''; float: left; padding-top: 50.8%; }

.intr-scheme__img { position: absolute; top: 0; left: 0; width: 100%; z-index: 0; }

.intr-scheme__point { position: absolute; z-index: 1; height: 30px; width: 30px; background-color: #ffffff; border-radius: 50%; font-size: 12px; font-weight: 700; line-height: 31px; color: #000000; text-align: center; transition-property: background-color, visibility, opacity; transition-duration: 0.3s; transition-timing-function: ease; visibility: hidden; opacity: 0; cursor: pointer; }

.intr-scheme__point.is-active { background-color: #ffc907; }

.intr-scheme__point:hover:not(.is-active) { background-color: #ffeaa0; }

.intr-scheme__popup { position: absolute; opacity: 0; visibility: hidden; z-index: 2; transition: all 0.3s ease; box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15); }

.intr-scheme__popup .store { margin: 0; }

.intr-scheme__popup.is-show { opacity: 1; visibility: visible; }

.intr-scheme__popup[x-placement^="left"] { margin-right: 20px; }

.intr-scheme__popup[x-placement^="left"] .intr-scheme__arrow { border-width: 10px 0 10px 10px; border-top-color: transparent; border-right-color: transparent; border-bottom-color: transparent; right: -10px; top: calc(50% - 20px); margin-left: 0; margin-right: 0; }

.intr-scheme__popup[x-placement^="left"] .store__inner { flex-direction: row-reverse; }

.intr-scheme__popup[x-placement^="left"] .store__left { margin: 0 0 0 20px; }

.intr-scheme__popup[x-placement^="left"] .store__right { text-align: right; }

.intr-scheme__popup[x-placement^="right"] { margin-left: 20px; }

.intr-scheme__popup[x-placement^="right"] .intr-scheme__arrow { border-width: 10px 10px 10px 0; border-left-color: transparent; border-top-color: transparent; border-bottom-color: transparent; left: -10px; top: calc(50% - 20px); margin-left: 0; margin-right: 0; }

.intr-scheme__popup[x-placement^="bottom"] { margin-top: 20px; }

.intr-scheme__popup[x-placement^="bottom"] .intr-scheme__arrow { border-width: 0 10px 10px 10px; border-left-color: transparent; border-right-color: transparent; border-top-color: transparent; top: -10px; left: calc(50% - 20px); margin-top: 0; }

.intr-scheme__popup[x-placement^="top"] { margin-bottom: 20px; }

.intr-scheme__popup[x-placement^="top"] .intr-scheme__arrow { border-width: 10px 10px 0 10px; border-left-color: transparent; border-right-color: transparent; border-bottom-color: transparent; bottom: -10px; left: calc(50% - 20px); margin-top: 0; margin-bottom: 0; }

.intr-scheme__arrow { position: absolute; width: 0; height: 0; border-style: solid; margin: 10px; border-color: white; }

.intr-scheme__clear-popup { float: right; margin-bottom: 15px; font-size: 12px; font-weight: 500; color: #7a7a7a; cursor: pointer; }

.intr-scheme.show-points .intr-scheme__point { opacity: 1; visibility: visible; }

.advice-block__inner { display: flex; flex-wrap: wrap; margin-right: -20px; }

.advice-block-link { text-align: right; font-size: 12px; font-weight: 500; margin-top: 20px; }

.advice-block-link a { border-bottom: 1px solid rgba(255, 255, 255, 0); transition: border 0.3s ease; }

.advice-block-link a:hover { border-color: rgba(0, 0, 0, 0.4); }

.advice { position: relative; width: calc(25% - 20px); min-height: 330px; background-color: white; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15); transition: box-shadow 0.3s ease; will-change: box-shadow; text-align: center; margin-right: 20px; }

.advice:hover { box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); }

.advice:hover .advice__category { color: black; }

.advice__preview { position: relative; overflow: hidden; }

.advice__preview-img { width: 100%; }

.advice__info { padding: 20px; box-sizing: border-box; }

.advice__link { position: absolute; font-size: 0; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; }

.advice__category { font-size: 12px; font-weight: 700; letter-spacing: 0.05em; color: #bdbdbd; text-transform: uppercase; margin-bottom: 10px; transition: color 0.3s ease; min-height: 22px; }

.advice__title { max-width: 210px; max-height: 72px; overflow: hidden; margin: 0 auto; font-size: 16px; line-height: 24px; }

.mall { padding-top: 23px; }

.mall__inner { display: flex; justify-content: space-between; }

.mall__left { width: calc(100% - 610px); margin-right: 30px; }

.mall__right { width: 580px; }

.mall__descr { max-width: 480px; }

.mall__title { font-size: 30px; font-weight: 500; margin-bottom: 20px; }

.mall__title-small { display: block; font-size: 12px; font-weight: 700; letter-spacing: 0.05em; margin-bottom: 0; text-transform: uppercase; color: #402d8d; }

.mall__title h1 { font-size: 30px; }

.mall__contacts { display: flex; flex-direction: row; align-items: center; margin-bottom: 20px; }

.mall__contacts-info { font-size: 12px; font-weight: 500; }

.mall__contacts-info:first-child { margin-bottom: 13px; }

.mall__btns { display: flex; flex-direction: row; flex-wrap: wrap; }

.mall__btn { margin-right: 24px; width: 100px; height: 100px; line-height: 1.4; white-space: normal; padding: 5px; align-items: center; justify-content: center; display: inline-flex; }

.swiper-slide { height: auto; }

.breadcrumb-wrapper { padding-top: 5px; margin-bottom: 20px; }

.breadcrumb li { font-size: 12px; line-height: 16px; display: inline-block; margin-bottom: 5px; color: black; }

.breadcrumb li:after { content: ""; background-image: url("../img/arrow.svg"); background-repeat: no-repeat; background-size: contain; background-position: center; display: inline-block; vertical-align: middle; height: 8px; width: 11px; margin: 0 10px 0 11px; }

.breadcrumb li:last-child { color: #7a7a7a; cursor: default; }

.breadcrumb li:last-child::after { display: none; }

.breadcrumb a { color: #000; }

.title-wrapper { display: flex; flex-flow: row nowrap; justify-content: space-between; align-items: flex-start; }

.title-wrapper__back { flex-shrink: 0; margin-left: 60px; }

.filters { margin-top: 30px; }

.subnav { margin-top: 15px; }

.filter { position: relative; display: inline-block; font-size: 12px; font-weight: 500; color: #7a7a7a; margin-right: 12px; margin-bottom: 20px; transition: color 0.3s ease; }

.filter:after { content: ''; position: absolute; bottom: -9px; left: 0; height: 2px; width: 100%; min-width: 25px; background-color: black; opacity: 0; transition: opacity 0.3s ease; }

.filter:last-child { margin-right: 0; }

.filter:hover:not(.filter-active) { cursor: pointer; color: black; }

.filter-active { color: black; }

.filter-active:after { opacity: 1; }

.subnav-wrapper { padding-bottom: 20px; }

.subnav.subnav_with-padding li { margin-bottom: 20px; }

.subnav li { position: relative; display: inline-block; font-size: 12px; font-weight: 500; color: black; margin-right: 12px; margin-bottom: 8px; }

.subnav li:after { content: ''; position: absolute; bottom: -9px; left: 0; height: 2px; width: 100%; min-width: 25px; background-color: black; opacity: 0; transition: opacity 0.3s ease; }

.subnav li a { color: #7a7a7a; transition: color 0.3s ease; }

.subnav li:hover a, .subnav li.is-active a { color: black; }

.subnav li.is-active:after { opacity: 1; }

.block-container { padding-bottom: 30px; }

.block-container--advert { padding-top: 20px; }

.block-container__top { display: flex; justify-content: flex-end; margin-bottom: 20px; }

.block-container__inner { display: flex; flex-wrap: wrap; }

.block-container__inner .swiper-slide { height: auto; }

.block-container .advice, .block-container .slide { margin-bottom: 20px; }

.block-container .interior { margin-bottom: 30px; }

.block-container .interior:last-child { margin-bottom: 20px; }

.block-container__bottom { margin-top: 30px; }

.block-container__bottom .btn { margin: 0 auto; }

.article { padding-bottom: 30px; }

.article_short { max-width: 780px; }

.article_short p { font-size: 14px; }

.article_sm { padding-bottom: 0; }

.article_sm p { font-size: 14px; }

.article_sm li { font-size: 14px !important; }

.article--no-indent { margin-bottom: 0; padding-bottom: 0; }

.article--no-indent p:last-child { margin-bottom: 0; }

.article__head { margin-bottom: 20px; }

.article__head-info { max-width: 580px; font-size: 27px; font-weight: 500; line-height: 1.11; }

.article__head-info span { display: block; margin-top: 3px; font-size: 12px; font-weight: 500; line-height: 1.67; color: #7a7a7a; }

.article__head-action { display: flex; justify-content: flex-end; flex-wrap: wrap; margin-top: 20px; }

.article__head-item { flex-shrink: 0; }

.article__head-item + .article__head-item { margin-left: 20px; }

.article__title { font-size: 30px; font-weight: bold; line-height: 1.2; margin-bottom: 24px; color: #000000; }

.article__inner--flex { display: flex; justify-content: space-between; flex-wrap: wrap; }

.article__row { display: flex; }

.article__left { width: calc(100% - 590px); max-width: 560px; margin-right: 20px; }

.article__left .store-list { margin-top: 57px; }

.article__right { width: 590px; margin-bottom: 20px; }

.article__full { width: 100%; margin-top: 70px; }

.article__full-wrapper { max-width: 780px; margin: 0 auto; }

.article__full-wrapper--top-indent { margin-top: 76px; }

.article__full--small-indent { margin-top: 5px; }

.article__full--no-indent { margin-top: 0; }

.article .article__reset-ul { list-style: none; margin: 0; padding: 0; }

.article .article__reset-ul li { padding: 0; }

.article .article__reset-ul li:before { display: none; }

.article__text { max-width: 580px; padding-right: 20px; }

.content .article__subtitle { margin-bottom: 25px !important; }

.media-wrapper { max-width: 580px; margin: 0 auto; }

.media-wrapper .gallery { margin-left: auto; margin-right: auto; }

.media-wrapper img { width: 100%; }

.media-wrapper--big { max-width: 980px; margin: 55px auto 20px; }

.media-wrapper--full { max-width: 100%; margin: 0 auto 20px; }

.media-wrapper--top-indent { margin-top: 45px; }

.brands { padding-bottom: 100px; }

.brands__sort-wrapper { display: flex; justify-content: flex-end; }

.brands__sort-wrapper .btn { margin-bottom: 20px; }

.brands__sort-wrapper .btn + .btn { margin-left: 20px; }

.brands__ctg-wrapper { background-color: white; box-sizing: border-box; padding: 30px 40px 20px; }

.brands__ctg-top { margin-bottom: 12px; }

.brands__ctg-group { display: flex; flex-wrap: wrap; }

.brands__ctg-item { font-size: 12px; font-weight: 500; color: #bdbdbd; transition: color 0.3s ease; margin-bottom: 10px; }

.brands__ctg-item:after { content: '/'; display: inline-block; color: #eeeeee; margin: 0 4px; }

.brands__ctg-item:last-child:after { display: none; }

.brands__ctg-item.is-active, .brands__ctg-item:hover { color: black; }

.brands__ctg-item:hover:not(.is-active) { cursor: pointer; }

.brands__ctg-block { display: none; }

.brands__ctg-block.is-active { display: block; }

.brands__wrapper { display: flex; flex-wrap: wrap; box-sizing: border-box; padding: 30px 60px 10px; background-color: white; }

.brands__inner { position: relative; width: 100%; }

.brands__inner:after { content: ''; display: block; clear: both; }

.brands__filter-value { text-align: center; font-size: 24px; font-weight: 500; margin: 30px auto 15px; }

.brand { width: 25%; min-width: 200px; font-size: 18px; line-height: 24px; transition: color 0.3s ease; margin-bottom: 13px; }

.brand .store__tags { display: none; }

.brand:hover { color: #4933a0; }

.brands-block { width: 100%; }

.plyr--stopped .plyr__controls { opacity: 0; visibility: hidden; }

.plyr__play-large { border: none; background-color: rgba(255, 255, 255, 0); outline: none; border: none; background-image: url("/static/img/bind-css/play.svg"); background-position: center; background-repeat: no-repeat; background-size: contain; width: 50px; height: 50px; }

.plyr__play-large svg { display: none; }

.plyr__video-wrapper { padding-bottom: 68.96% !important; }

.article__right .gallery { max-width: 580px; }

.review-intro { max-width: 480px; }

.tabs { padding-bottom: 15px; }

.tabs__title { text-transform: uppercase; font-size: 14px; font-weight: bold; line-height: 1.7; letter-spacing: .7px; color: #000000; margin-bottom: 25px; }

.tabs__triggers { display: flex; align-items: baseline; margin-bottom: 20px; }

.tabs__triggers--bottom { justify-content: flex-end; margin-bottom: 0; margin-top: 40px; }

.tabs__triggers--bottom .tabs__trigger { margin-bottom: 0; }

.tabs__triggers--bottom .tabs__trigger.is-active:after { display: none; }

.tabs__trigger { position: relative; display: inline-block; font-size: 12px; font-weight: 500; color: #7a7a7a; margin-right: 12px; margin-bottom: 30px; transition: color 0.3s ease; }

.tabs__trigger:after { content: ""; position: absolute; bottom: -9px; left: 0; height: 2px; width: 100%; min-width: 25px; background-color: #000; opacity: 0; transition: opacity 0.3s ease; }

.tabs__trigger.is-active { color: black; }

.tabs__trigger.is-active:after { opacity: 1; }

.tabs__trigger:hover:not(.is-active) { color: black; cursor: pointer; }

.tabs__item { display: none; }

.action-shops { padding: 0 60px 60px 60px; background-color: #fff; margin-bottom: 40px; overflow-y: scroll; background: linear-gradient(90deg, #fff 30%, rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 70%) 0 100%, radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.2), transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.2), transparent) 0 100%; background-repeat: no-repeat; background-color: #fff; background-size: 40px 100%,40px 100%,14px 100%,14px 100%; background-position: 0 0,100%,0 0,100%; background-attachment: local,local,scroll,scroll; }

.action-shops__discount { text-align: right; color: black; font-family: GothamPro-Medium; font-size: 16px; font-weight: 600; line-height: 20px; }

.action-shops__select { position: relative; outline: none; appearance: none; margin: 30px 0 20px 0; background: white; border: none; border-radius: 0px; height: 50px; max-width: 475px; width: 100%; padding: 10px 20px; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpolygon points='4.5 3.603 8.269 0 9 .699 4.5 5 0 .699 .731 0' transform='translate(0 1)'/%3E%3C/svg%3E%0A"); background-position: right 50%; background-size: 40px 6px; background-repeat: no-repeat; }

.action-shops__table { width: 100%; font-family: GothamPro-Bold; }

.action-shops__table th { font-size: 12px; font-weight: 400; letter-spacing: 0.6px; line-height: 18px; text-transform: uppercase; text-align: left; padding: 40px 80px 20px 0; white-space: nowrap; }

.action-shops__table th:not(:first-child) { border-bottom: 2px solid #ffd43a; }

.action-shops__table td { vertical-align: middle; padding-top: 10px; padding-right: 80px; font-weight: 600; line-height: 1.4em; }

.action-shops__table td:first-child, .action-shops__table th:first-child { padding-right: 0; width: 120px; }

.action-shops__table td:nth-child(2), .action-shops__table th:nth-child(2) { padding-left: 30px; padding-right: 30px; width: 295px; }

.action-shops__table td:nth-child(3), .action-shops__table th:nth-child(3) { padding-left: 30px; padding-right: 30px; width: 130px; }

.action-shops__table td:nth-child(4), .action-shops__table th:nth-child(4) { padding-left: 30px; padding-right: 30px; width: 185px; }

.action-shops__table td:nth-child(5), .action-shops__table th:nth-child(5) { padding-left: 40px; padding-right: 0; width: auto; }

.action-shops__table a { color: #402d8d; font-size: 12px; letter-spacing: 0.6px; line-height: 20px; text-transform: uppercase; }

.action-shops__table .text-right { text-align: right; }

.services { display: flex; justify-content: center; flex-wrap: wrap; margin-right: -20px; padding: 20px 0; }

.services .swiper-wrapper { flex-wrap: wrap; }

.js-services-slider, .js-advice-slider { margin-left: -20px; margin-right: -20px; padding-left: 20px; }

.js-services-slider { margin-top: -20px; }

.service { width: calc(25% - 20px); height: 330px; margin-right: 20px; text-align: center; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15); transition: box-shadow 0.3s ease; }

.service:hover { box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); }

.service__inner { display: flex; flex-direction: column; justify-content: space-between; width: 100%; height: 100%; box-sizing: border-box; padding: 20px 30px 35px; }

.service__icon { width: 40px; height: 40px; margin: 0 auto 30px; }

.service__icon-img { max-width: 100%; max-height: 100%; }

.service__title { font-size: 18px; font-weight: 600; letter-spacing: 0.05em; line-height: 24px; text-transform: uppercase; margin-bottom: 20px; }

.service__descr { font-size: 12px; line-height: 18px; margin-bottom: 10px; }

.service__bottom .btn { margin: 0 auto; }

.full-service { width: calc(50% - 20px); margin-right: 20px; margin-bottom: 20px; position: relative; }

.full-service__inner { position: relative; }

.full-service__preview { background-color: #fff; position: relative; width: 52%; overflow: hidden; }

.full-service__preview-img { display: block; max-height: 400px; min-height: 325px; }

.full-service__info { z-index: 1; position: absolute; right: 0; top: 0; bottom: 0; display: flex; flex-direction: column; justify-content: space-between; flex-shrink: 0; width: 280px; box-sizing: border-box; padding: 40px 50px 35px 20px; background-color: #ffc907; text-align: right; color: white; }

.full-service__info-bottom .btn { margin-left: 30px; }

.full-service__title { font-size: 18px; font-weight: bold; letter-spacing: 0.05em; line-height: 24px; margin-bottom: 30px; text-transform: uppercase; }

.full-service__descr { font-size: 14px; font-weight: 500; line-height: 18px; max-height: 125px; overflow: hidden; }

.full-service__sep { margin-bottom: 35px; }

.full-service__intro { font-size: 16px; color: #010101; line-height: 24px; }

.full-service__intro .full-service__sep { margin-bottom: 25px; }

.full-service--white .full-service__info { background-color: white; color: black; }

.full-service--right .full-service__info { padding: 74px 20px 35px 50px; text-align: left; }

.full-service--right .full-service__info-bottom .btn { margin-left: 0; }

.full-service--special { position: relative; height: auto; min-height: 400px; }

.full-service--special .full-service__info { display: block; width: 100%; padding: 50px 50px 30px; text-align: left; background-color: white; color: #010101; }

.full-service--special .full-service__title { color: black; margin-bottom: 34px; }

.full-service--special .full-service__intro { font-size: 14px; font-weight: 500; line-height: 18px; margin-bottom: 26px; }

.full-service--special .full-service__bottom { font-size: 12px; line-height: 18px; }

.full-service--special ul, .sl-advert-full ul { margin-bottom: 25px; }

.full-service--special ul li, .sl-advert-full ul li { font-size: 12px; line-height: 16px; margin-bottom: 10px; }

.full-service--special ul li:last-child, .sl-advert-full ul li:last-child { margin-bottom: 0; }

.full-service--special ul li:before, .sl-advert-full ul li:before { content: "\2022"; display: inline-block; margin-right: 14px; }

.store-filters { transition: opacity 0.3s ease; }

.store-filters.is-disable { opacity: 0.6; pointer-events: none; }

.store-list { margin-top: 20px; }

.store-list--no-indent { margin-top: 0; margin-bottom: 0; }

.store-list--sm-indent { margin-top: 13px; }

.store-list--flex { display: flex; flex-wrap: wrap; margin-right: -20px; width: calc(100% + 20px); }

.store-list--flex .store { width: calc(100% / 3 - 20px); margin-right: 20px; }

.store-list--flex .store--small { width: calc(100% / 4 - 20px); max-width: 280px; min-width: 240px; min-height: 140px; }

.store-list--bottom-indent { margin-bottom: 30px; }

.store-info { margin-bottom: 40px; }

.store-info__inner { display: block; }

.store-info__aside { display: flex; margin-right: 10px; }

.store-info__main { display: flex; flex-direction: column; flex: 1 0 auto; }

.store-info__header { margin-bottom: 5px; }

.store-info__logo { position: relative; flex-shrink: 0; width: 180px; height: 180px; margin-right: 20px; }

.store-info__logo-img { position: absolute; width: 100%; top: 0; left: 0; }

.store-info__item { margin-bottom: 4px; }

.store-info__item:last-child { margin-bottom: 0; }

.store-info__name { font-size: 12px; font-weight: 500; color: #7a7a7a; line-height: 1.5; }

.store-info__email { font-size: 12px; font-weight: 500; color: #000000; line-height: 1.5; }

.store-info__btn { margin-top: auto; }

.store-info__cols { display: flex; flex-direction: row; }

.store-info__col { margin-right: 16px; flex: auto; display: flex; flex-direction: column; align-items: flex-start; }

.store-info__col:last-child { margin-right: 0; }

.store-info__col_end { display: flex; flex-direction: column; justify-content: flex-end; }

.store-btn { min-width: 160px; display: inline-block; vertical-align: bottom; }

.store-btn__inner { display: flex; flex-direction: column; justify-content: space-between; color: #ffffff; text-align: center; }

.store-btn__item { padding-left: 20px; padding-right: 20px; display: block; }

.store-btn__top { padding-bottom: 7px; background-color: #402d8d; display: inline-flex; flex-direction: column; align-items: center; justify-content: center; flex-wrap: wrap; }

.store-btn__numb { font-size: 30px; }

.store-btn__sector { font-size: 46px; line-height: 1.2; margin-right: 10px; }

.store-btn__bottom { background-color: #ffc907; padding-top: 9px; padding-bottom: 9px; }

.store-btn__descr { color: #000000; font-size: 12px; font-weight: 500; }

.store-btn__show { font-size: 13px; }

.store { width: 100%; height: auto; max-width: 380px; min-height: 150px; background-color: white; margin-bottom: 20px; }

.store a { border-bottom: none; }

.store__inner { display: flex; box-sizing: border-box; padding: 20px; padding-left: 30px; }

.store__left { margin-right: 20px; }

.store__right { text-align: left; }

.store__logo { display: block; position: relative; height: 60px; width: 60px; margin-bottom: 20px; }

.store__logo-img { position: absolute; top: 50%; left: 50%; max-width: 100%; max-height: 100%; transform: translate(-50%, -50%); }

.store__section { display: block; font-size: 12px; font-weight: 700; color: #402d8d; text-align: center; transition: color 0.3s ease; }

.store__section:hover { color: #5d43c3; }

.store__name { font-size: 12px; font-weight: 700; letter-spacing: 0.05em; line-height: 16px; text-transform: uppercase; margin-bottom: 1px; transition: color 0.3s ease; }

.store__name a { border-bottom: none; transition: color 0.3s ease; }

.store__name a:hover { color: #402d8d; }

.store__category { font-size: 11px; line-height: 14px; margin-bottom: 17px; }

.store__contacts { font-size: 12px; font-weight: 500; line-height: 18px; margin-bottom: 10px; }

.store__tags { font-size: 12px; color: #7a7a7a; line-height: 18px; }

.store__tags a { display: inline-block; color: #7a7a7a; transition: color 0.3s ease; }

.store__tags a:hover { color: black; }

.store__point { position: absolute; z-index: 1; height: 30px; width: 30px; bottom: 20px; right: 20px; background-color: #ffc907; border-radius: 50%; font-size: 12px; font-weight: 700; line-height: 31px; color: #000000; text-align: center; }

.store__link:hover .store__name { color: #402d8d; }

.store__close { cursor: pointer; outline: none; padding: 0; border: none; background-color: transparent; position: absolute; left: 10px; top: 5px; z-index: 1; font-size: 30px; line-height: 1; }

.store--small { position: relative; cursor: pointer; transition: box-shadow 0.3s ease; }

.store--small .store__name { margin-bottom: 4px; }

.store--small .store__section { text-align: left; }

.store--small .store__contacts { margin-bottom: 15px; }

.store--small .store__category { margin-bottom: 15px; }

.store--small.is-active, .store--small:hover { box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); }

.store--scheme { cursor: pointer; transition: box-shadow 0.3s ease; }

.store--scheme:hover:not(.is-active) { box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }

.store--scheme.is-active { box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); }

.store-filters__category, .map-section__btn { position: relative; display: inline-block; font-size: 12px; font-weight: 500; color: #7a7a7a; margin-right: 12px; margin-bottom: 10px; padding-bottom: 10px; transition: color 0.3s ease; }

.store-filters__category:after, .map-section__btn:after { content: ''; position: absolute; bottom: 0; left: 0; height: 2px; width: 100%; min-width: 25px; background-color: black; opacity: 0; transition: opacity 0.3s ease; }

.store-filters__category:last-child, .map-section__btn:last-child { margin-right: 0; }

.store-filters__category:hover:not(.is-active), .map-section__btn:hover:not(.is-active) { cursor: pointer; color: black; }

.store-filters__category.is-active, .is-active.map-section__btn { color: black; }

.store-filters__category.is-active:after, .is-active.map-section__btn:after { opacity: 1; }

.store-filters__form { margin-top: 20px; }

.categories-section { margin: 40px 0 10px; }

.categories-section__part { margin-bottom: 28px; }

.categories-section__part:last-child { margin-bottom: 0; }

.category-list { font-size: 0; }

.category-list__item { display: inline-block; font-size: 12px; font-weight: 500; color: #000; transition: color 0.3s ease; margin-bottom: 12px; cursor: pointer; margin-right: 20px; }

.category-list__item.is-active { color: black; cursor: default; }

.category-list__item:hover { color: black; }

.category-list--uppercase { text-transform: uppercase; }

.category-list--uppercase .category-list__item { color: black; }

.category-list-title { display: inline-block; font-size: 12px; font-weight: bold; margin-bottom: 20px; color: #000000; line-height: 1.33; letter-spacing: 0.6px; text-transform: uppercase; }

.category-list--light .category-list__item { color: #bdbdbd; }

.category-list--light .category-list__item.is-active { color: black; }

.category-list--light .category-list__item:after { color: #eee; }

a.category-list-title { color: #402d8d; }

.form-container { position: relative; display: flex; flex-wrap: wrap; margin-right: -20px; }

.form-container--subscribe .input-wrapper { margin-bottom: 0; }

.form-container--subscribe .form-message { background-color: #e8e8e8; padding: 0; width: calc(100% - 20px); }

.form_md { max-width: 480px; }

.form__row { margin-bottom: 24px; }

.form__inner { display: flex; flex-direction: column; }

.form__description { font-size: 12px; font-weight: bold; color: #7a7a7a; }

.form__description:before { content: ''; display: inline-block; vertical-align: middle; margin-right: 5px; width: 0; height: 0; border-style: solid; border-width: 0 16px 16px 0; border-color: transparent #ffc907 transparent transparent; }

.form__footer { margin-top: 43px; }

.form__btn { font-weight: bold; }

.input, .select { position: relative; background-color: #ffffff; }

.input_focused .input__placeholder { font-size: 9px; top: 4px; }

.input_required:after { content: ''; position: absolute; top: 0; right: 0; width: 0; height: 0; border-style: solid; border-width: 0 16px 16px 0; border-color: transparent #ffc907 transparent transparent; }

.input_valid:after { border-color: transparent #7a7a7a transparent transparent; }

.input__field, .select2-container--default .select2-selection--single { position: relative; z-index: 1; background-color: transparent; }

.input__placeholder { position: absolute; top: 16px; padding: 0 20px; font-size: 12px; font-weight: 700; line-height: 1.5; color: #7a7a7a; transition: top .2s ease, font-size .2s ease; }

.form-message { display: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%; box-sizing: border-box; padding: 40px; background-color: white; text-align: center; z-index: 2; }

.form-message:before { content: ''; height: 100%; width: 0; display: inline-block; vertical-align: middle; }

.form-message__text { display: inline-block; vertical-align: middle; font-size: 16px; font-weight: 500; }

.input-wrapper { position: relative; width: calc(100% / 3 - 20px); min-width: 220px; margin-right: 20px; margin-bottom: 20px; }

.input-wrapper--small { width: calc(25% - 20px); }

.input-wrapper--big { width: 100%; max-width: 360px; }

.input-wrapper--medium { width: calc(25%); }

.input-wrapper--flex { width: calc(100% / 2 - 20px); max-width: 100%; }

.input-wrapper--full { width: 100%; }

.input-wrapper--top-indent { margin-top: 50px; }

.droplist { position: relative; width: 100%; }

.droplist__head { width: 100%; height: 50px; box-sizing: border-box; padding: 0 55px 0 20px; line-height: 52px; background-color: white; font-size: 12px; font-weight: 500; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; cursor: pointer; transition: box-shadow 0.3s ease; }

.droplist__head:hover { box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); }

.droplist__head:after { content: ''; position: absolute; width: 6px; height: 9px; right: 35px; top: 20px; background: url("/static/img/bind-css/slider-arrow-black.png") center no-repeat; background-size: contain; transform: rotate(90deg); z-index: 1; transition: transform 0.3s ease; }

.droplist__body { position: absolute; width: 100%; left: 0; top: calc(100% + 10px); font-size: 0; background-color: white; box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); visibility: hidden; opacity: 0; z-index: 2; }

.droplist__body-wrapper { box-sizing: border-box; padding: 30px 30px 10px; max-height: 200px; overflow-y: scroll; }

.droplist__body-wrapper::-webkit-scrollbar { width: 5px; background-color: white; }

.droplist__body-wrapper::-webkit-scrollbar-thumb { border-radius: 3px; background-color: #efefef; }

.droplist__body-arrow { position: absolute; width: 0; height: 0; border-style: solid; border-color: white; margin: 10px; border-width: 0 10px 10px 10px; border-left-color: transparent; border-right-color: transparent; border-top-color: transparent; margin-top: 0; top: -10px; left: 50%; transform: translateX(-50%); }

.droplist__body-close { position: absolute; top: 15px; right: 20px; height: 14px; width: 14px; background: url("/static/img/bind-css/close.svg") no-repeat center/contain; cursor: pointer; }

.droplist__item { font-size: 12px; font-weight: 500; color: #bdbdbd; transition: color 0.3s ease; margin-bottom: 15px; }

.droplist__item.is-selected { color: black; }

.droplist__item:after { content: '/'; display: none; color: #eeeeee; margin: 0 4px; }

.droplist__item:last-child:after { display: none; }

.droplist__item:hover:not(.is-selected) { color: black; cursor: pointer; }

.droplist.is-disabled .droplist__head { color: #bdbdbd; }

.droplist.is-disabled .droplist__head:after { opacity: 0.3; }

.droplist.is-disabled .droplist__head:hover { cursor: default; box-shadow: none; }

.droplist.is-opened .droplist__head { box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); }

.droplist.is-opened .droplist__head:after { transform: rotate(270deg); }

.droplist.is-opened .droplist__body { top: calc(100% + 20px); visibility: visible; opacity: 1; transition: all 0.3s ease; }

.droplist--full .droplist__body-wrapper { padding: 30px 145px 20px 40px; }

.droplist--full .droplist__item { display: inline-block; margin-bottom: 10px; }

.droplist--full .droplist__item:after { display: inline-block; }

.droplist--full .droplist__item:last-child:after { display: none; }

.droplist--full .droplist__body-wrapper { max-height: 100%; }

.droplist--full .droplist__body { width: 1180px; }

.droplist--full .droplist__body-arrow { left: 0; transform: none; }

.droplist--full.is-opened .droplist__body { top: calc(100% + 30px); }

.droplist--checkbox .droplist__item { cursor: pointer; }

.submit-btn { height: 50px; line-height: 52px; font-size: 12px; font-weight: 500; text-align: center; background-color: #402d8d; color: white; }

.text-field, .select2-container--default .select2-selection--single { width: 100%; height: 50px; box-sizing: border-box; border: none; outline: none; font-size: 12px; font-weight: 500; color: #000000; text-align: left; line-height: 50px; padding: 0 20px; transition: box-shadow 0.3s ease; will-change: box-shadow; }

.text-field--area { font-family: 'GothamPro', sans-serif; padding: 20px; color: #000000; height: 150px; resize: none; line-height: 20px; }

.text-field:hover, .select2-container--default .select2-selection--single:hover { box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }

.text-field:focus, .select2-container--default .select2-selection--single:focus { box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2); }

label.error { display: block; margin-top: 7px; font-size: 12px; font-weight: 500; letter-spacing: 0.04em; color: #de0a0a; }

.review-form-wrapper { padding-bottom: 100px; }

input[type="submit"] { outline: none; }

input::-ms-clear { display: none; }

.subscribe { display: flex; justify-content: flex-end; }

.subscribe-form-wrapper { display: none; flex-shrink: 0; width: 100%; }

.subscribe-btn { flex-shrink: 0; }

.select__filed { width: 100%; }

.select2-container--default .select2-selection--single { width: 100%; display: flex; align-items: center; flex-direction: row; justify-content: space-between; }

.select2-container--default .select2-selection--single .select2-selection__arrow { position: relative; top: 0; right: 0; margin-right: -5px; }

.select2-container .select2-selection--single .select2-selection__rendered { padding-left: 0; }

.map { width: 100%; height: 700px; }

.map-section { position: relative; margin-bottom: 60px; }

.map-section__wrapper { display: flex; flex-direction: row; justify-content: space-between; align-items: flex-end; margin-bottom: 35px; }

.map-section__btn { margin-bottom: 0; }

.map-section__contacts { margin-top: 10px; }

.taxi-widget { position: relative; top: -10px; }

.map-contacts__inner { box-sizing: border-box; display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; }

.map-contacts__block { margin-bottom: 45px; }

.map-contacts__block:first-child { width: 100%; }

.map-contacts__title { text-transform: uppercase; font-size: 14px; font-weight: 700; line-height: 16px; margin-bottom: 16px; }

.map-contacts__info { font-size: 14px; line-height: 1.7; display: flex; flex-direction: column; }

.email { color: #402d8d; }

.contacts-img { margin-top: 8px; text-align: right; }

.contacts-img img { max-width: 380px; width: 100%; }

.schedule__wrapper { padding-bottom: 40px; }

.schedule__section { display: flex; flex-wrap: wrap; }

.schedule__item { box-sizing: border-box; height: 40px; width: 60px; margin-bottom: 20px; background-color: white; border: 2px solid white; text-align: center; line-height: 38px; font-size: 12px; font-weight: 700; letter-spacing: 0.05em; }

.schedule__item.is-active { background-color: #ffc907; border-color: #ffc907; }

.schedule__col, .schedule__col-wrapper { margin-right: 40px; }

.schedule__col:last-child, .schedule__col-wrapper:last-child { margin-right: 0; }

.schedule__subtitle { position: relative; text-transform: uppercase; font-size: 12px; line-height: 1.5; font-weight: 500; }

.schedule__subtitle:after { content: ''; display: block; margin-top: 5px; height: 4px; background-color: #ffc907; margin-bottom: 16px; }

.schedule__items { width: 100%; display: flex; flex-direction: row; justify-content: space-between; }

.contacts-add { display: flex; justify-content: space-between; margin-bottom: 20px; }

.contacts-add__title { font-size: 12px; font-weight: 500; line-height: 24px; margin-bottom: 5px; }

.contacts-add__descr { font-size: 12px; line-height: 24px; }

.full-map { width: 100%; height: 100vh; }

.full-map-container { position: fixed; width: 100%; height: 100vh; left: 0; top: 0; background-color: white; opacity: 0; visibility: hidden; transform: translate(0, 100vh); transition-property: opacity, visibility, transform; transition-duration: 0.5s; transition-timing-function: ease-in-out; z-index: 100; }

.full-map-container.is-opened { opacity: 1; visibility: visible; transform: translate(0, 0); }

.full-map-close { position: absolute; width: 40px; height: 40px; top: 10px; right: 10px; cursor: pointer; z-index: 1; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15); background: white url("/static/img/bind-css/close.svg") no-repeat center/20px 20px; }

.sector_name { color: #FFFFFF; font-family: arial; font-size: 11px; font-weight: bold; position: absolute; display: none; }

.scheme { position: relative; margin-bottom: 95px; transition: opacity 0.3s ease; }

.scheme img { max-width: 100%; width: 100%; }

.scheme-container { background: #cccccc; }

.scheme.is-loading { opacity: 0.4; pointer-events: none; }

.scheme-filters { margin-bottom: 70px; }

.scheme-filters__corp { display: flex; flex-wrap: wrap; margin-bottom: 30px; }

.scheme-filters__categories { background: white; padding: 30px 40px; box-sizing: border-box; }

.scheme-filters .category-list { margin: 0; }

.corp-btn { position: relative; width: 80px; height: 50px; box-sizing: border-box; text-align: center; line-height: 48px; color: black; border: 2px solid black; font-size: 12px; font-weight: 700; letter-spacing: 0.05em; margin-right: 20px; margin-bottom: 20px; transition: all 0.3s ease; }

.corp-btn:last-child { margin-right: 0; }

.corp-btn--big { width: 180px; }

.corp-btn.is-active, .corp-btn:hover { border: 2px solid #402d8d; background-color: #402d8d; color: white; }

.corp-btn:hover:not(.is-active) { cursor: pointer; }

.corp-btn__count { position: absolute; top: -15px; right: -15px; height: 30px; width: 30px; background-color: #ffc907; border-radius: 50%; font-size: 12px; font-weight: 700; color: #000000; text-align: center; letter-spacing: 0.05em; line-height: 31px; padding: 1px 0 0 1px; box-sizing: border-box; }

.scheme-group { position: relative; }

.scheme-group__head { position: absolute; display: flex; left: 0; top: 30px; width: 100%; z-index: 1; }

.scheme-group__item { position: relative; display: none; }

.scheme-group__item.is-active { display: block; }

.scheme-group-btn { height: 80px; width: 80px; margin-right: 20px; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15); background-color: white; text-align: center; line-height: 82px; transition: background-color 0.3s ease; text-transform: uppercase; transition: box-shadow 0.3s ease; }

.scheme-group-btn__letter { display: inline-block; vertical-align: middle; margin-right: 15px; margin-top: 2px; font-size: 60px; font-weight: 900; color: #402d8d; transition: color 0.3s ease; }

.scheme-group-btn__text { display: inline-block; vertical-align: middle; font-size: 12px; font-weight: 700; color: #000000; letter-spacing: 0.05em; transition: color 0.3s ease; }

.scheme-group-btn--big { width: 180px; }

.scheme-group-btn:hover:not(.is-active) { cursor: pointer; box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3); }

.scheme-group-btn.is-active { background-color: #402d8d; color: white; }

.scheme-group-btn.is-active .scheme-group-btn__letter, .scheme-group-btn.is-active .scheme-group-btn__text { color: white; }

.scheme-popup { box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15); z-index: 1; }

.scheme-popup[x-placement^="left"] { margin-right: 20px; }

.scheme-popup[x-placement^="left"] .intr-scheme__arrow { border-width: 10px 0 10px 10px; border-top-color: transparent; border-right-color: transparent; border-bottom-color: transparent; right: -10px; top: calc(50% - 20px); margin-left: 0; margin-right: 0; }

.scheme-popup[x-placement^="left"] .store__inner { flex-direction: row-reverse; }

.scheme-popup[x-placement^="left"] .store__left { margin: 0 0 0 20px; }

.scheme-popup[x-placement^="left"] .store__right { text-align: right; }

.scheme-popup[x-placement^="right"] { margin-left: 20px; }

.scheme-popup[x-placement^="right"] .intr-scheme__arrow { border-width: 10px 10px 10px 0; border-left-color: transparent; border-top-color: transparent; border-bottom-color: transparent; left: -10px; top: calc(50% - 20px); margin-left: 0; margin-right: 0; }

.scheme-popup[x-placement^="bottom"] { margin-top: 20px; }

.scheme-popup[x-placement^="bottom"] .intr-scheme__arrow { border-width: 0 10px 10px 10px; border-left-color: transparent; border-right-color: transparent; border-top-color: transparent; top: -10px; left: calc(50% - 20px); margin-top: 0; }

.scheme-popup[x-placement^="top"] { margin-bottom: 20px; }

.scheme-popup[x-placement^="top"] .intr-scheme__arrow { border-width: 10px 10px 0 10px; border-left-color: transparent; border-right-color: transparent; border-bottom-color: transparent; bottom: -10px; left: calc(50% - 20px); margin-top: 0; margin-bottom: 0; }

.scheme-popup .store { width: 380px; margin-bottom: 0; }

.shop-info { position: absolute; top: 0; left: 0; z-index: 1; }

.pse-element { position: absolute; width: 0; height: 0; }

.count-store-block { display: none; }

.count-store { position: absolute; width: 100px; font-size: 12px; text-align: center; padding: 10px; background-color: white; z-index: 1; box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15); }

.count-store:after { content: ''; width: 0; height: 0; border-style: solid; margin: 0 10px; border-color: white; position: absolute; border-width: 10px 10px 0 10px; border-left-color: transparent; border-right-color: transparent; border-bottom-color: transparent; bottom: -10px; left: calc(50% - 20px); }

.category-group { padding-bottom: 80px; }

.category-group__title h2 { margin-bottom: 25px; }

.tooltip { display: inline-block; box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4); position: absolute; left: 0; top: 0; transform: translate(-33px, -100%); margin-top: -15px; }

.tooltip__triangle { content: ''; position: absolute; top: 100%; left: 24px; width: 20px; height: 20px; transform: rotate(45deg); background-color: #ffffff; margin-top: -14px; }

.tooltip__inner { min-height: 117px; display: inline-flex; }

.tooltip__title { font-size: 12px; font-weight: 700; line-height: 1.33; letter-spacing: .6px; color: #402d8d; text-decoration: none; text-transform: uppercase; display: flex; flex-direction: row; margin-bottom: 24px; }

.tooltip__title:hover, .tooltip__title:active, .tooltip__title:focus { text-decoration: none; color: #000000; }

.tooltip__categories { color: #000000; line-height: 1.5; font-weight: bold; font-size: 12px; }

.tooltip__aside { background-color: #ffffff; text-align: center; padding: 10px 10px 20px; display: flex; flex-direction: column; justify-content: space-between; }

.tooltip__body { background-color: #f2f2f2; padding: 20px 30px 20px 10px; display: flex; flex-direction: column; justify-content: space-between; }

.tooltip__logo { margin-bottom: 10px; width: 60px; height: 60px; }

.tooltip__logo img { max-width: 100%; }

.tooltip__code { color: #402d8d; font-size: 12px; font-weight: bold; line-height: 1.33; letter-spacing: .6px; text-transform: uppercase; }

.tooltip__link { font-size: 18px; font-weight: 500; line-height: 1.39; color: #000000; }

.tooltip__link:hover, .tooltip__link:active, .tooltip__link:focus { text-decoration: none; color: #000000; }

.tooltip.is-hidden { opacity: 0; visibility: hidden; }

.tooltip__close { position: absolute; right: 5px; top: 5px; border: none; padding: 0; background-color: transparent; font-size: 20px; width: 20px; height: 20px; cursor: pointer; }

.scheme { margin-bottom: 50px; margin-top: -15px; }

.scheme__form { box-shadow: 0 2px 24px 0 rgba(64, 45, 141, 0.33); position: relative; background-color: #fff; }

.scheme__form input { border-bottom: 2px solid transparent; }

.scheme__form input:focus { border-bottom-color: #5239b4; }

.scheme__clear-btn { background-color: transparent; border: none; padding: 0; position: absolute; color: #7a7a7a; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer; z-index: 1; font-size: 12px; font-weight: 600; }

.scheme__search-input { padding-right: 80px; }

.scheme__nav { display: flex; justify-content: center; width: 100%; }

.scheme__header { z-index: 2; position: absolute; width: 100%; padding: 40px 0 30px 0; }

.scheme__category { box-shadow: 0 2px 24px 0 rgba(64, 45, 141, 0.33); }

.scheme__category .select2-container { width: 100% !important; }

.scheme__body { position: relative; }

.scheme__body:-webkit-full-screen { width: 100vw; height: 100vh; }

.scheme__body:-webkit-full-screen .scheme__map { height: 100vh; width: 100vw; }

.scheme__body:full-screen { width: 100vw; height: 100vh; }

.scheme__body:full-screen .scheme__map { height: 100vh; width: 100vw; }

.scheme__body:-moz-full-screen { width: 100vw; height: 100vh; }

.scheme__body:-moz-full-screen .scheme__map { height: 100vh; width: 100vw; }

.scheme__map { height: 90vh; overflow: hidden; position: relative; background-color: #E8E8E8; }

.scheme__map svg { user-select: none; width: 100%; height: 100%; }

.scheme__map svg [data-shop] { transition: fill .2s ease; cursor: pointer; }

.scheme__map svg [data-shop].is-selected, .scheme__map svg [data-shop]:hover { fill: #7F7E7E !important; }

.scheme__map svg [data-shop].is-highlighted { fill: #ffc907; }

.scheme__map svg text { pointer-events: none; font-weight: 500; font-family: 'TTCommons', sans-serif; }

.scheme__map svg text:not([data-text-colors]) { fill: #000; line-height: 20px; }

.scheme__controls { width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center; visibility: hidden; }

.scheme__control { cursor: pointer; border: none; background-color: #fff; width: 50px; height: 50px; display: flex; flex-direction: row; justify-content: center; align-items: center; transition: all .25s ease; outline: 0; fill: #000; color: #000000; box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4); visibility: visible; stroke: #000; margin-right: 8px; }

.scheme__control:hover { background-color: #402d8d; fill: #fff; stroke: #fff; color: #ffffff; }

.scheme__control:last-child { margin-right: 0; }

.scheme__control:hover, .scheme__control:active, .scheme__control:focus { outline: 0; }

.scheme__link { position: relative; display: flex; align-items: center; justify-content: center; margin-right: 20px; border-bottom: 4px solid #402d8d; padding-top: 4px; width: 50px; height: 50px; background-color: #402d8d; color: #ffffff; font-weight: bold; box-shadow: 0 2px 24px 0 rgba(64, 45, 141, 0.33); }

.scheme__link:after { content: attr(data-count); position: absolute; bottom: 100%; left: 100%; display: flex; justify-content: center; align-items: center; margin-left: -15px; margin-bottom: -15px; width: 30px; height: 30px; border-radius: 50%; background-color: #ffc907; font-size: 12px; font-weight: bold; color: #000000; }

.scheme__link:last-child { margin-right: 0; }

.scheme__link.is-active { border-bottom-color: #ffc907; background-color: #ffffff; color: #000000; }

.error-page { position: relative; }

.error-page__background { z-index: 0; position: absolute; top: -22px; left: 50%; transform: translateX(-50%); font-family: 'GothamPro'; font-size: 608px; font-weight: bold; text-transform: uppercase; color: #402d8d; opacity: 0.05; }

@media screen and (max-width: 1200px) { .error-page__background { font-size: 500px; } }

@media screen and (max-width: 1000px) { .error-page__background { font-size: 400px; } }

@media screen and (max-width: 860px) { .error-page__background { top: 40px; font-size: 300px; } }

@media screen and (max-width: 560px) { .error-page__background { top: 0; } }

.error-page__content { z-index: 1; position: relative; display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 80px 0; }

@media screen and (max-width: 1000px) { .error-page__content { padding: 60px 0; } }

.error-page__big-text { display: inline-block; font-family: 'GothamPro'; font-size: 208px; font-weight: bold; line-height: 185px; text-transform: uppercase; color: #402d8d; margin-bottom: 15px; }

@media screen and (max-width: 560px) { .error-page__big-text { font-size: 135px; line-height: 1.1; margin-bottom: 0; } }

.error-page__small-text { display: inline-block; font-family: 'GothamPro'; font-size: 32px; font-weight: bold; letter-spacing: 1.6px; line-height: 42px; text-transform: uppercase; color: #402d8d; }

@media screen and (max-width: 560px) { .error-page__small-text { font-size: 19px; line-height: 1.2; } }

@media screen and (max-width: 1200px) { .schedule__col-wrapper { margin-bottom: 20px; }
  .schedule__col-wrapper:last-child { margin-bottom: 0; }
  .schedule__section { align-items: flex-start; }
  .section__row { flex-wrap: wrap; flex-direction: column; }
  .section__left { width: 100%; }
  .section__right { max-width: 80%; width: auto; }
  .store-info .store-info__inner { display: block; }
  .store-info .store-info__aside { margin-bottom: 15px; flex-wrap: wrap; }
  .store-info .store-info__logo { margin-bottom: 20px; }
  .store-info .store-info .phone-list__item { display: block; }
  .store-info .store-info__cols { display: inline-flex; flex-direction: column; align-items: flex-start; }
  .store-info .store-info__col { margin-bottom: 15px; }
  .store-info .store-info__col:last-child { margin-bottom: 0; } }

@media screen and (max-width: 1050px) { .header__flag { margin-left: 0; margin-right: 10px; margin-bottom: 8px; }
  .header__left { width: calc(100% - 40px); }
  .header__mobile-btns .contact-info { margin-right: 10px; }
  .fixed-logo { display: none !important; }
  .header { padding-bottom: 0; border-bottom: none; }
  .header--fixed { position: relative; }
  .header--fixed .header { padding-top: 15px; }
  .header--fixed .menu-btn { order: unset; }
  .header--fixed .header__logo-wrapper { width: 100%; }
  .header-wrapper.is-opened .nav { display: block; box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16); }
  .header-wrapper .search { display: block; }
  .header-wrapper .search:not(.search--mobile) { display: none; }
  .header-wrapper .search__text { font-size: 10px; }
  .header__left { padding: 0 20px; margin-bottom: 16px; }
  .header__logo { width: 160px; }
  .header__inner { flex-wrap: wrap; margin-left: -20px; margin-right: -20px; }
  .header .menu-btn { display: block !important; order: 1; flex: 1 0 auto; margin: 0; height: 40px; width: auto; padding-left: 17px; padding-right: 17px; }
  .header .menu-btn:after { display: none; }
  .header__btns { flex: 6; order: 2; }
  .header__btn { margin-left: 0; height: 40px; line-height: 40px; flex: 1 0 auto; }
  .header__contacts { align-items: flex-start; margin-left: 0; margin-top: 30px; }
  .header__mobile-btns { display: flex; }
  .search-container { top: 100%; bottom: auto; }
  .contact-info { margin-bottom: 20px; }
  .contact-info:last-child { margin-bottom: 0; }
  .contacts-img { text-align: left; }
  .social-wrapper { margin-left: 0; }
  .nav { padding-top: 30px; position: absolute; top: 100%; left: 0; right: 0; display: none; padding-bottom: 30px; overflow: auto; z-index: 1111; background-color: #fff; }
  .nav__close { visibility: visible; opacity: 1; }
  .nav__inner { display: block; }
  .nav__list { margin-left: 0; }
  .nav__item { display: block; margin-bottom: 20px; }
  .nav__item:last-child { margin-bottom: 0; }
  .nav__link { padding: 0; }
  .nav__link--yellow { background-color: transparent; color: #ffc907; }
  .sl-advert-small__inner { padding: 100px 30px 25px; }
  .sl-product__info { padding: 13px 20px 20px; }
  .sl-product__title { font-size: 14px; line-height: 20px; max-height: 80px; }
  .service { width: calc(50% - 20px); min-width: 280px; margin-bottom: 20px; }
  .service .service__descr { font-size: 14px; line-height: 20px; }
  .full-service__info { width: 250px; padding: 55px 30px 25px 20px; }
  .full-service--right .full-service__info { padding: 55px 20px 25px 30px; }
  .full-service--special .full-service__info { padding: 40px 30px 20px; }
  .advice { width: calc(33.3333% - 20px); margin-bottom: 20px; }
  .mall__inner { display: block; }
  .mall__left { max-width: 100%; margin-bottom: 45px; }
  .mall__right { width: 100%; max-width: 580px; margin: 0 auto; }
  .mall__contacts { margin-bottom: 30px; display: block; }
  .mall__btn { margin-right: 20px; margin-bottom: 20px; }
  .section__row--ordered .section__right { order: -1; }
  .article__inner { display: block; }
  .article__inner--flex { display: flex; flex-direction: column; align-items: center; }
  .article__inner--flex .article__right { order: -1; }
  .article__row { display: block; }
  .article__left { width: 100%; max-width: 100%; margin-bottom: 45px; margin-right: 0; }
  .article__right { width: 100%; margin: 0 auto 30px; }
  .footer__top { display: block; }
  .footer__categories { width: 100%; }
  .footer__contacts { max-width: 500px; display: flex; justify-content: space-between; margin: 30px auto; }
  .footer__contacts .contact-info--location { margin: 0 20px 0 0; }
  .footer__bottom { flex-direction: column; margin-top: 15px; }
  .footer__bottom-left { width: 100%; max-width: 500px; margin: 0 auto 30px; }
  .footer__studio-link { order: 1; margin: 0 auto 20px; }
  .footer__nav { text-align: center; margin-bottom: 20px; }
  .footer__rights { text-align: center; order: 2; }
  .map-section__btn { display: block; height: auto; width: 155px; font-size: 11px; font-weight: 700; letter-spacing: 0.05em; line-height: 1.2; margin-bottom: 15px; padding-bottom: 10px; }
  .map-section__btn:last-child { margin-bottom: 0; }
  .map-contacts__inner { padding: 0; margin-left: -10px; margin-right: -10px; }
  .map-contacts__block { width: calc(50% - 20px); padding-left: 10px; padding-right: 10px; }
  .map-contacts__block:first-child { width: calc(50% - 20px); }
  .schedule__section { flex-direction: column; }
  .invite__main { padding: 24px 32px; flex: 70%; }
  .invite__link { left: -16px; }
  .invite__bg { position: relative; flex: 30%; } }

@media screen and (max-width: 992px) { .slider-subcontainer { margin-left: 0; }
  .scheme__nav, .scheme__controls { margin-top: 16px; justify-content: flex-end; } }

@media screen and (max-width: 960px) { .map { height: 600px; }
  .map-section__wrapper { position: static; transform: none; margin-left: 0; margin-right: 0; }
  .map-section__btns { justify-content: center; }
  .map-section__contacts { position: static; }
  .invite__link { left: 0; min-width: initial; }
  .invite__bg { position: relative; } }

@media screen and (max-width: 860px) { .header__right { justify-content: space-between; margin: 10px 0 0; }
  .review { width: 100%; }
  .store-list--flex .store { width: calc(50% - 20px); }
  .important-text { margin: 50px 0 100px; }
  .subscribe { flex-wrap: wrap; width: 100%; }
  .subscribe-form-wrapper { margin-bottom: 20px; }
  .form-container--subscribe { justify-content: flex-end; }
  .action-shops { padding: 0 10px 30px 10px; }
  .action-shops .store__section { text-align: right; }
  .action-shops__table { min-width: 320px; border: 0; }
  .action-shops__table thead { border: none; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
  .action-shops__table td:first-child, .action-shops__table th:first-child, .action-shops__table td:nth-child(2), .action-shops__table th:nth-child(2), .action-shops__table td:nth-child(3), .action-shops__table th:nth-child(3), .action-shops__table td:nth-child(4), .action-shops__table th:nth-child(4), .action-shops__table td:nth-child(5), .action-shops__table th:nth-child(5) { width: 100%; padding: 10px 30px; }
  .action-shops__table tr { display: block; border-bottom: 1px solid #ddd; }
  .action-shops__table td { position: relative; width: 100%; display: block; text-align: right; padding-left: 50% !important; border-bottom: 1px solid #ddd; }
  .action-shops__table td::before { position: absolute; left: 30px; content: attr(data-label); float: left; font-weight: bold; text-align: left; }
  .action-shops__table td:last-child { border-bottom: 0; }
  .invite { flex-direction: column; max-height: initial; }
  .invite__main { padding: 16px 40px 0 16px; }
  .invite__link { display: block; left: initial; transform: initial; top: initial; position: initial; }
  .invite__bg { position: relative; flex: 1; padding: 20px 16px 16px; } }

@media screen and (max-width: 800px) { .brands__ctg-group { margin-bottom: 10px; }
  .brands__ctg-group:last-child { margin-bottom: 0; }
  .swiper-wrapper { width: 100%; }
  .block-container__inner { margin-right: 0; }
  .full-service, .services, .js-advice-slider { margin-right: -20px; padding-right: 0; }
  .js-advice-slider, .services { padding-right: 20px; }
  .full-service, .sl-advert, .sl-advert-full, .sl-event, .sl-news, .full-service { width: 100%; }
  .brand-slide { width: calc(25% - 20px) !important; }
  .schedule__section { flex-wrap: wrap; align-items: flex-start; margin-right: -20px; }
  .schedule__col-wrapper { align-items: flex-start; }
  .schedule__col-wrapper .schedule__col:ntn-child(2n) { flex-direction: row-reverse; }
  .sl-news__inner, .full-service__inner, .sl-advert__inner { display: flex; }
  .sl-news__info, .full-service__info, .sl-advert__info { position: static; flex: 1 0 auto; }
  .sl-news__preview, .full-service__preview, .sl-advert__preview { width: auto; }
  .advice { width: calc(50% - 20px); } }

@media screen and (max-width: 767px) { .scheme__form { margin-bottom: 8px; }
  .scheme__header { padding-top: 8px; }
  .scheme__search-input { height: 32px; }
  .scheme__header .select .select2 .select2-selection--single { height: 32px; }
  .scheme__nav, .scheme__controls { justify-content: flex-start; }
  .mall__left { width: 100%; }
  .title-wrapper { flex-direction: column; }
  .title-wrapper__back { margin-top: 20px; margin-left: 0; } }

@media screen and (max-width: 640px) { .store-info__btn { width: 278px; max-width: 100%; margin-top: 20px; }
  .header .menu-btn { font-size: 10px; }
  .header__btn { font-size: 10px; width: auto; }
  .header .social-btn { width: 25px; height: 25px; margin-right: 15px; }
  .advice-block__inner, .services { display: block; }
  .services { margin-bottom: 0; }
  .services .swiper-wrapper { flex-wrap: nowrap; }
  .services .swiper-wrapper .service { width: auto; min-width: auto; }
  .block-container .advice, .block-container .service, .block-container .slide { margin: 0 auto 20px; }
  .block-container--advert { max-width: 100%; margin: 0 auto; }
  .advice, .service { margin: 0 auto 20px; }
  .advice { width: 100%; height: auto; min-height: 330px; }
  .advice__info { padding: 20px 30px; }
  .slider-link { bottom: -30px; }
  .footer__categories { flex-wrap: wrap; }
  .footer__categories-list { width: calc(50% - 15px); max-width: calc(50% - 15px); }
  .footer__nav li { margin-bottom: 10px; }
  .footer .social-btn:last-child { margin-right: 0; }
  .store-list--flex { margin-right: 0; display: block; width: auto; }
  .store-list--flex .store { width: 100%; margin: 0 auto 20px; }
  .lSAction > a { width: 50px; height: 50px; }
  .map-section__wrapper { width: 100%; box-sizing: border-box; display: flex; flex-wrap: wrap; }
  .map-section__btns { position: static; margin-top: 40px; order: 1; width: 100%; justify-content: center; }
  .map-section__btn:first-child { margin-left: 0; }
  .map-section__contacts { width: 100%; order: 2; }
  .map-contacts__block { width: 100%; margin-bottom: 25px; }
  .map-contacts__block:first-child { width: 100%; }
  .corp-btn { width: 50px; }
  .corp-btn--big { width: 140px; }
  .form-container--subscribe .input-wrapper { width: 100%; }
  .form-container--subscribe .input-wrapper--big { max-width: 100%; margin-bottom: 20px; }
  .sl-advert-full.sl-advert-full--bf .sl-advert-full__descr, .sl-advert-full.sl-advert-full--bf p, .sl-advert-full.sl-advert-full--bf ul li { font-size: 14px; line-height: 22px; }
  .sl-advert-full .sl-advert-full__title { max-width: 67%; }
  .sl-advert-full .sl-advert-full__title-big { font-size: 40px; }
  .sl-news__inner, .full-service__inner, .sl-advert__inner { flex-direction: column; }
  .sl-news__preview-img, .full-service__preview-img, .sl-advert__preview-img { max-height: 100%; min-height: auto; width: 100%; }
  .sl-news__info, .full-service__info, .sl-advert__info { width: 100%; text-align: left; }
  .sl-news__info-bottom, .full-service__info-bottom, .sl-advert__info-bottom { margin-top: 15px; }
  .sl-news__info-bottom .btn, .full-service__info-bottom .btn, .sl-advert__info-bottom .btn { margin-left: 0; }
  .sl-news__preview-img { width: 100%; }
  .interior { display: block; height: auto; }
  .interior__preview { width: 100%; }
  .interior__preview-img { position: static; }
  .interior__info { width: auto; height: auto; }
  .interior__descr { margin-bottom: 20px; }
  .interior--special .interior__preview, .interior--special .interior__info { width: 100%; }
  .slider { padding-bottom: 40px; }
  .slider--indent { padding-top: 20px; }
  .slider--main { padding-bottom: 10px; }
  .section__row { margin-right: 0; margin-left: 0; }
  .section__left, .section__right { padding-left: 0; padding-right: 0; }
  .section__right { max-width: 100%; }
  .invite__date { font-size: 14px; line-height: 20px; }
  .invite__name { font-size: 18px; line-height: 22px; } }

@media screen and (max-width: 480px) { .service { width: 100%; min-width: 100%; height: auto; }
  .service__top { margin-bottom: 10px; }
  .interior__info { padding: 30px; }
  .interior--special .interior__info { padding: 90px 30px 30px; }
  .interior--special .interior__info:before { top: 30px; }
  .brand-slide { width: calc(50% - 20px) !important; }
  .mall__contacts { flex-wrap: wrap; }
  .mall__contacts-block:last-child { width: 100%; margin-top: 20px; }
  .review__inner { padding: 30px; }
  .pluses__inner { justify-content: space-between; }
  .plus { width: calc(50% - 20px); margin-bottom: 30px; }
  .plus__icon { height: 70px; width: 70px; }
  .plus__title { font-size: 14px; line-height: 22px; }
  .footer__contacts { display: block; width: 220px; }
  .footer__contacts .contact-info--location { margin: 0 0 20px; }
  .footer__bottom-left { flex-direction: column; align-items: center; }
  .footer__social { margin: 10px 0 25px; }
  .article__full { margin-top: 0; }
  .media-wrapper--big { max-width: 980px; margin: 35px auto 20px; }
  .brands__wrapper { padding: 30px 30px 10px; }
  .lSAction > a { width: 30px; height: 30px; }
  .intr-scheme__point { width: 22px; height: 22px; line-height: 22px; font-size: 11px; }
  .lSSlideOuter .lSPager.lSGallery { padding: 0 10px 15px; }
  .lSSlideOuter .lSPager.lSGallery li.active, .lSSlideOuter .lSPager.lSGallery li:hover { box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2) !important; }
  .important-text { font-size: 28px; line-height: 34px; margin: 50px 0 70px; }
  .content h1 { font-size: 26px; line-height: 1.17; letter-spacing: 0.04em; }
  .content h2 { font-size: 24px; line-height: 30px; }
  .input-wrapper { width: 100%; }
  .droplist__body-wrapper { padding: 25px 20px 10px; }
  .droplist.is-opened .droplist__body { top: calc(100% + 20px); }
  .map-section__btns { display: block; }
  .map-section__btn { margin: 0 0 20px; }
  .contacts-add { display: block; }
  .contacts-add__col:first-child { margin-bottom: 20px; }
  .schedule__section { display: inline-block; margin-right: 0; }
  .schedule__col-wrapper { width: auto; margin-right: 0; }
  .schedule__col { margin-right: 5px; }
  .schedule__items { justify-content: flex-start; }
  .schedule__item { width: 54px; }
  .map-contacts { width: auto; } }

@media screen and (max-width: 380px) { .wrapper { padding: 0 15px; } }

@media screen and (min-width: 640px) { .js-services-slider .slider-pagination-container, .js-advice-slider .slider-pagination-container { display: none; } }

.invite { transition: all .3s ease; position: fixed; bottom: 20px; left: 50%; transform: translate(-50%, 100%); visibility: hidden; opacity: 0; width: calc(100% - 20px); max-width: 1146px; z-index: 10; background-color: #FFC907; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4); display: flex; flex-direction: row; max-height: 160px; }

.invite.active { visibility: visible; opacity: 1; transform: translate(-50%, 0); }

.invite__main { transition: all .3s ease; padding: 24px 32px; flex: 65%; }

.invite__type { font-size: 12px; line-height: 20px; }

.invite__date { font-size: 18px; line-height: 20px; color: white; }

.invite__name { font-size: 30px; line-height: 36px; font-weight: bold; margin: 0; }

.invite__link { transition: all .3s ease; position: absolute; left: -24px; top: 50%; transform: translateY(-50%); background-color: white; color: black; font-size: 12px; text-align: center; line-height: 1; padding: 19px 24px; min-width: 272px; font-weight: bold; }

.invite__bg { transition: all .3s ease; position: relative; flex: 35%; }

.invite__bg img { width: 100%; height: 100%; object-fit: cover; }

.invite__close { cursor: pointer; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M1.7098.2933c-.3912-.391-1.0253-.391-1.4165 0-.391.3912-.391 1.0253 0 1.4165L8.0836 9.5.2933 17.2902c-.391.3912-.391 1.0253 0 1.4165.3912.3911 1.0253.3911 1.4165 0L9.5 10.9164l7.7902 7.7903c.3912.3911 1.0253.3911 1.4165 0 .3911-.3912.3911-1.0253 0-1.4165L10.9164 9.5l7.7903-7.7902c.3911-.3912.3911-1.0253 0-1.4165-.3912-.391-1.0253-.391-1.4165 0L9.5 8.0836 1.7098.2933z' clip-rule='evenodd'/%3E%3C/svg%3E%0A"); background-repeat: no-repeat; background-position: center; width: 20px; height: 20px; position: absolute; right: 15px; top: 15px; }

.job-list__grid { display: flex; flex-flow: row wrap; margin-right: -20px; width: 100%; }

@media screen and (max-width: 760px) { .job-list__grid { margin-right: 0; } }

.job-list__item { transition: box-shadow .3s ease; background-color: white; width: calc(100% / 2 - 20px); margin: 0 20px 20px 0; display: flex; flex-flow: column nowrap; justify-content: flex-start; padding: 20px; }

.job-list__item:hover { box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 760px) { .job-list__item { width: 100%; margin: 0 0 10px 0; } }

.job-list__date { font-size: 12px; line-height: 16px; color: #7A7A7A; }

.job-list__title-row { display: flex; flex-flow: row nowrap; justify-content: space-between; align-items: flex-start; width: 100%; margin-top: 10px; }

.job-list__title { font-weight: bold; font-size: 12px; line-height: 16px; letter-spacing: 0.6px; text-transform: uppercase; color: #402D8D; }

.job-list__title:hover { color: #5239B4; }

.job-list__salary { font-weight: bold; font-size: 12px; line-height: 16px; text-align: right; color: #000000; }

.job-list__place { display: inline-block; font-weight: 500; font-size: 12px; line-height: 16px; color: #7A7A7A; margin-top: 5px; }

.job-list__about { font-size: 12px; line-height: 18px; color: #000000; margin-top: 15px; margin-bottom: 25px; }

.job-list__link { margin-top: auto; font-weight: 500; font-size: 12px; line-height: 16px; color: #402D8D; }

.job-list__link:hover { color: #5239B4; }

.job-item h2 { font-weight: bold; font-size: 12px; line-height: 18px; color: #000000; margin-top: 30px; margin-bottom: 10px; max-width: 780px; }

.job-item p { font-size: 12px; line-height: 18px; color: #000000; max-width: 780px; }

.job-item a { display: inline-block; transition: color .2s ease; font-size: 12px; line-height: 18px; color: #402D8D; }

.job-item a:hover { color: #5239B4; }

.job-item ul { list-style: disc; max-width: 510px; }

.job-item ul li { font-size: 12px; line-height: 18px; color: #000000; margin-bottom: 10px; margin-left: 15px; }

.job-item .job-item__company { font-weight: bold; font-size: 30px; line-height: 36px; color: #000000; margin-bottom: 30px; }

.job-item .job-item__salary { font-weight: 500; font-size: 18px; line-height: 20px; color: #000000; margin-bottom: 5px; }

.job-item .job-item__date { font-weight: 500; font-size: 12px; line-height: 20px; color: #7A7A7A; margin-bottom: 10px; }

.job-item .job-item__contact-title { font-weight: bold; font-size: 14px; line-height: 24px; letter-spacing: 0.7px; text-transform: uppercase; color: #000000; }

.advert-timer { font-family: 'GothamPro', sans-serif; padding: 8px 0; position: relative; }

@media screen and (max-width: 1000px) { .advert-timer { padding: 12px 0; } }

.advert-timer .wrapper { display: flex; flex-flow: row nowrap; align-items: center; }

@media screen and (max-width: 500px) { .advert-timer .wrapper { flex-flow: column nowrap; align-items: flex-start; } }

.advert-timer__link { position: absolute; left: 0; top: 0; width: 100%; height: 100%; font-size: 0; z-index: 1; }

.advert-timer__button { display: inline-flex; font-size: 14px; line-height: 16px; font-weight: 500; padding: 8px 16px; margin-right: 24px; }

@media screen and (max-width: 768px) { .advert-timer__button { display: none; } }

.advert-timer__text { margin: 0 48px 0 0; color: inherit; font-size: 16px; line-height: 24px; font-weight: 500; }

@media screen and (max-width: 640px) { .advert-timer__text { margin: 0 24px 0 0; } }

@media screen and (max-width: 500px) { .advert-timer__text { font-size: 14px; line-height: 20px; margin: 0 0 16px 0; } }

.advert-timer__clock { flex-shrink: 0; transition: opacity .3s ease; margin-left: auto; display: flex; flex-flow: row nowrap; font-size: 16px; line-height: 24px; font-weight: 500; opacity: 0; }

@media screen and (max-width: 1000px) { .advert-timer__clock { flex-flow: column nowrap; } }

@media screen and (max-width: 500px) { .advert-timer__clock { margin-left: 0; flex-flow: row nowrap; font-size: 14px; line-height: 20px; } }

.advert-timer__clock-inner-wr { display: flex; flex-flow: row nowrap; }

.advert-timer__clock-title { font-weight: 500; font-size: 16px; line-height: 24px; opacity: 0.7; color: inherit; margin: 0 16px 0 0; }

@media screen and (max-width: 500px) { .advert-timer__clock-title { font-size: 14px; line-height: 20px; } }

.advert-timer__clock-item { display: flex; }

.advert-timer__clock-item + .advert-timer__clock-item { margin-left: 8px; }

.advert-timer__clock-item-value { display: block; margin-right: 4px; width: 22px; text-align: right; }

.advert-timer__clock-item-value.js-clock-days { width: auto; }

@media screen and (max-width: 500px) { .advert-timer__clock-item-value { width: 19px; } }

.advert-timer__clock-item-name { display: block; }

.justwidget--asst-pic { width: 50px !important; height: 50px !important; }

.justwidget--asst-pic-waves { bottom: -55px !important; border-width: 30px !important; }

.justwidget--asst.right { top: -140px; right: 40px; }

@media (max-width: 768px) { body:not(.justwidget-iframe--content) .justwidget--asst.right { top: -140px; right: 35px; } }
